import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  list: [],
  next: null,
  previous: null,
  count: 0,
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_ORDERS:
      return {
        ...state,
        count: payload.count,
        previous: payload.previous,
        next: payload.next,
        list: [...payload.results],
      };
    case types.SET_MORE_ORDERS:
      return {
        ...state,
        count: payload.count,
        previous: payload.previous,
        next: payload.next,
        list: [...state.list, ...payload.results],
      };
    default:
      return state;
  }
};

const defaultPageState = 0;

const page = (state = defaultPageState, { type, payload }) => {
  switch (type) {
    case types.SET_PAGE_ORDERS:
      return payload;
    case types.INCREMENT_PAGE_ORDERS:
      return state + 1;
    case types.DECREMENT_PAGE_ORDERS:
      return state - 1;
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingOrders: false,
  refreshingOrders: false,
  moreOrders: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: payload,
      };
    case types.TOGGLE_REFRESHING_ORDERS:
      return {
        ...state,
        refreshingOrders: payload,
      };
    case types.TOGGLE_MORE_ORDERS:
      return {
        ...state,
        moreOrders: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorMain: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_ORDERS:
      return {
        ...state,
        errorMain: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  error,
  page,
});
