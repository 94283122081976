import types from './types';

const togglePendingService = (payload, meta) => ({
  type: types.TOGGLE_PENDING_SERVICE,
  payload,
  meta,
});

const toggleRefreshingService = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_SERVICE,
  payload,
  meta,
});

const setService = (payload, meta) => ({
  type: types.SET_SERVICE,
  payload,
  meta,
});

const setErrorService = (payload, meta) => ({
  type: types.SET_ERROR_SERVICE,
  payload,
  meta,
});

const exportedObject = {
  togglePendingService,
  toggleRefreshingService,
  setService,
  setErrorService,
};

export default exportedObject;
