import { useReducer, useContext, createContext } from 'react';

const LayoutStateContext = createContext();
const LayoutDispatchContext = createContext();

export const layoutTypes = {
  SET_HEADER_LEFT: 'layout/SET_HEADER_LEFT',
  SET_HEADER_TITLE: 'layout/SET_HEADER_TITLE',
  SET_HEADER_TITLE_ALIGN: 'layout/SET_HEADER_TITLE_ALIGN',
  SET_HEADER_RIGHT: 'layout/SET_HEADER_RIGHT',
};

const layoutDefaults = {
  headerLeft: null,
  headerTitle: null,
  headerTitleAlign: 'left',
  headerRight: null,
};

const layoutReducer = (layout, action) => {
  switch (action.type) {
    case layoutTypes.SET_HEADER_LEFT: {
      return {
        ...layout,
        headerLeft: action.payload,
      };
    }
    case layoutTypes.SET_HEADER_TITLE: {
      return {
        ...layout,
        headerTitle: action.payload,
      };
    }
    case layoutTypes.SET_HEADER_TITLE_ALIGN: {
      return {
        ...layout,
        headerTitleAlign: action.payload,
      };
    }
    case layoutTypes.SET_HEADER_RIGHT: {
      return {
        ...layout,
        headerRight: action.payload,
      };
    }
    default: {
      return layout;
    }
  }
};

const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(layoutReducer, layoutDefaults);

  const setHeaderLeft = (value) => {
    dispatch({
      type: layoutTypes.SET_HEADER_LEFT,
      payload: value,
    });
  };

  const setHeaderTitle = (value, align = 'left') => {
    dispatch({
      type: layoutTypes.SET_HEADER_TITLE,
      payload: value,
    });
    dispatch({
      type: layoutTypes.SET_HEADER_TITLE_ALIGN,
      payload: align,
    });
  };

  const setHeaderRight = (value) => {
    dispatch({
      type: layoutTypes.SET_HEADER_RIGHT,
      payload: value,
    });
  };

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider
        value={{
          dispatch,
          setHeaderLeft,
          setHeaderTitle,
          setHeaderRight,
        }}
      >
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

const useLayoutDispatch = () => {
  const context = useContext(LayoutDispatchContext);

  if (context === undefined) {
    throw new Error(`useLayoutDispatch must be used within a LayoutProvider`);
  }
  return context;
};

const useLayoutState = () => {
  const context = useContext(LayoutStateContext);

  if (context === undefined) {
    throw new Error(`useLayoutState must be used within a LayoutProvider`);
  }
  return context;
};

export { LayoutProvider, useLayoutDispatch, useLayoutState };
