import { GET_MENU_ITEMS, GET_HOTEL_PICS } from 'src/constants';

import { http } from 'src/helpers/http';

export const getItems = async ({ menu, page = 1 }) => {
  const response = await http.get(GET_MENU_ITEMS, {
    params: {
      page_size: 999,
      page: page,
      menu: menu,
    },
  });

  return response;
};

export const getHotelImages = async (hotelId) => {
  const response = await http.get(GET_HOTEL_PICS.replace(':id', hotelId), {
    params: {
      tag: 'hotel',
    },
  });

  return response;
};

const exportedObject = {
  getItems,
  getHotelImages,
};

export default exportedObject;
