const TOGGLE_PENDING_ORDER_STATUSES = 'helpers/TOGGLE_PENDING_ORDER_STATUSES';
const SET_ORDER_STATUSES = 'helpers/SET_ORDER_STATUSES';

const TOGGLE_PENDING_GEO_LEVELS = 'helpers/TOGGLE_PENDING_GEO_LEVELS';
const SET_GEO_LEVELS = 'helpers/SET_GEO_LEVELS';

const TOGGLE_PENDING_MESSAGES_TYPES = 'helpers/TOGGLE_PENDING_MESSAGES_TYPES';
const SET_MESSAGES_TYPES = 'helpers/SET_MESSAGES_TYPES';

const TOGGLE_PENDING_LANGUAGES = 'helpers/TOGGLE_PENDING_LANGUAGES';
const SET_LANGUAGES = 'helpers/SET_LANGUAGES';

const TOGGLE_PENDING_UPDATE = 'helpers/TOGGLE_PENDING_UPDATE';
const SET_UPDATE = 'helpers/SET_UPDATE';
const SET_ERROR_UPDATE = 'helpers/SET_ERROR_UPDATE';

const TOGGLE_PENDING_SYSTEM = 'helpers/TOGGLE_PENDING_SYSTEM';
const SET_SYSTEM = 'helpers/SET_SYSTEM';
const SET_ERROR_SYSTEM = 'helpers/SET_ERROR_SYSTEM';

const exportedObject = {
  TOGGLE_PENDING_ORDER_STATUSES,
  SET_ORDER_STATUSES,
  TOGGLE_PENDING_GEO_LEVELS,
  SET_GEO_LEVELS,
  TOGGLE_PENDING_MESSAGES_TYPES,
  SET_MESSAGES_TYPES,
  TOGGLE_PENDING_LANGUAGES,
  SET_LANGUAGES,
  TOGGLE_PENDING_UPDATE,
  SET_UPDATE,
  SET_ERROR_UPDATE,
  TOGGLE_PENDING_SYSTEM,
  SET_SYSTEM,
  SET_ERROR_SYSTEM,
};

export default exportedObject;
