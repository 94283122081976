export const LS_TOKEN = 'yha';
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const WS_BASE_URL = process.env.REACT_APP_WS_URL;
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION;
export const USE_LOCAL_STORAGE = process.env.REACT_APP_USE_LOCAL_STORAGE === 'false' ? false : true;

export const PROJECT = process.env.REACT_APP_PROJECT ?? 'hotels';
export const TITLE = process.env.REACT_APP_TITLE ?? 'Your Hotel App';

export const POST_ACTIVATION = '/auth/users/activation/';
export const POST_RESET_PASSWORD_CONFIRM = '/auth/users/reset_password_confirm/';

export const GET_STAYS = '/stays/';
export const GET_STAY = '/stays/:id/';
export const POST_STAY = '/stays/';

export const GET_ORDERS = '/service_orders/detailed/';
export const POST_ORDER = '/service_orders/';
export const GET_ORDER = '/service_orders/:id/';
export const POST_ORDER_FEEDBACK_INFO = '/reviews/items/info/';
export const POST_ORDER_FEEDBACK_REQUEST = '/reviews/items/';
export const POST_ORDER_CANCEL = '/service_orders/:id/cancel/';
export const GET_ORDER_STATUSES = '/service_orders/statuses/';

export const GET_SERVICES = '/services/common/';
export const GET_SERVICE = '/services/:id/';
export const GET_GEO_LEVELS = '/services/geo_levels/';

export const GET_CATEGORIES = '/categories/';
export const GET_CATEGORY = '/categories/:id/';

export const GET_VCATEGORY = '/virtual-categories/:id/';
export const GET_VCATEGORY_ITEMS = '/virtual-categories-items/';

export const POST_TOKEN = '/devices/';

export const GET_MENUS = '/menus/';
export const GET_MENU_ITEMS = '/menu_items/';

export const GET_HOTELS = '/hotels/';

export const GET_MESSAGES_TYPES = '/messages/types/';
export const POST_TRANSLATE_MESSAGE = '/messages/:id/translate/';

export const GET_NOTIFICATIONS_GROUPS = '/messages/groups/';

export const GET_CONFIG = '/config/';
export const GET_LANGUAGES = '/config/langs/';
export const POST_UPDATE = '/config/version/';

export const GET_HOTEL_PICS = '/images/hotel/:id/';
export const GET_ROOM_PICS = '/images/room/:id/';

export const GET_GUIDE_CATEGORIES = '/content/sights-categories/';
export const GET_GUIDE_ITEMS = '/content/sights/';
export const GET_GUIDE_ITEM = '/content/sights/:id/';

export const GET_NEARBY_CATEGORIES = '/content/safety-categories/';
export const GET_NEARBY_ITEMS = '/content/safety/';
export const GET_NEARBY_ITEM = '/content/safety/:id/';

export const POST_REGISTER = '/auth/users/';
export const POST_SET_PASSWORD = '/auth/users/set_password/';
export const POST_RESET_PASSWORD = '/auth/users/reset_password/';
export const POST_LOGIN = '/auth/token/';
export const POST_REFRESH = '/auth/token/refresh/';
export const POST_LOGIN_BY_OAUTH = '/auth/social/jwt-pair-user/';
export const GET_LOGIN_BY_OAUTH = '/auth/social/jwt-pair-user/';
export const POST_SET_EMAIL = '/auth/users/set_email/';

export const GET_USERS_ME = '/auth/users/me/';
export const PATCH_USERS_ME = '/auth/users/me/';

export const GET_SYSTEM = '/services/system_available/';

export const POST_FCM_DEVICE_CREATE = '/fcm_devices/';

export const GET_SERVICE_MENU_ITEM = '/service_menu/menu_items/:id/';

export const GET_LANDINGS = '/promo/landings/';

export const TERMS_URL =
  process.env.REACT_APP_IS_PRODUCTION === true
    ? 'https://dashboard.yourhotelapp.com/docs/terms.html'
    : 'https://dashboard.stage.yourhotelapp.com/docs/terms.html';

export const GOOGLE_CLIENT_ID = '361289592541-mea4a6c06a1qfnid9d72i28rbfvkhqj0.apps.googleusercontent.com';
export const VK_APP_ID = '52233924';
