const UpdateIcon = (props) => {
  return (
    <svg width={188} height={164} viewBox={'0 0 188 164'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="m100.94 12.11 80.194 139.912a8 8 0 0 1-6.94 11.978H13.806a8 8 0 0 1-6.94-11.978L87.059 12.109a8 8 0 0 1 13.882 0Z"
          fill="#FFF"
        />
        <path
          d="M94.293 81c8.725 0 16.919 3.888 22.538 10.543l5.135-3.774c.85-.623 2.034-.005 2.034 1.064v20.856c0 .898-.866 1.53-1.7 1.24l-19.232-6.728c-.987-.344-1.181-1.68-.335-2.302l4.66-3.424c-3.406-3.607-8.1-5.714-13.1-5.714-6.76 0-12.93 3.784-16.101 9.873l-.14.242-10.68-3.678c.08-.709.288-1.414.636-2.082C73.186 87.176 83.258 81 94.293 81Zm-28.56 30.073 19.62 6.843c1.006.352 1.203 1.712.34 2.342l-5.203 3.814c3.391 3.213 7.81 5.032 12.549 5.032 6.954 0 13.212-3.882 16.402-10.152l11.052 3.801c-.1.41-.246.816-.439 1.21C114.92 134.473 104.568 141 93.04 141c-8.538 0-16.462-3.574-22.167-9.88l-4.797 3.516c-.866.635-2.075.005-2.075-1.081v-21.221c0-.915.884-1.557 1.734-1.261Z"
          fill="#1A1A1C"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default UpdateIcon;
