import nearbyActions from './actions';

import nearbyAPI from '../../api/nearby';

const getCategories = () => async (dispatch, getState) => {
  const { nearby } = getState();
  const { progress } = nearby;

  if (progress.pendingCategories) return;

  dispatch(nearbyActions.togglePendingCategories(true));
  dispatch(
    nearbyActions.setErrorCategories({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await nearbyAPI.getCategories();

    dispatch(nearbyActions.setCategories(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        nearbyActions.setErrorCategories({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        nearbyActions.setErrorCategories({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(nearbyActions.togglePendingCategories(false));
  }
};

const getItems = (categoryId) => async (dispatch, getState) => {
  const { nearby, profile } = getState();
  const { progress } = nearby;
  const cityId = profile.data.stayInfo?.hotel?.city?.id;

  if (progress.pendingItems) return;

  dispatch(nearbyActions.togglePendingItems(true));
  dispatch(
    nearbyActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await nearbyAPI.getItems({
      category: categoryId,
      city: cityId,
      page: 1,
    });

    dispatch(nearbyActions.setPageItems(1));
    dispatch(nearbyActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        nearbyActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        nearbyActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(nearbyActions.togglePendingItems(false));
  }
};

const refreshItems = (categoryId) => async (dispatch, getState) => {
  const { nearby, profile } = getState();
  const { progress } = nearby;
  const cityId = profile.data.stayInfo?.hotel?.city?.id;

  if (progress.refreshingItems) return;

  dispatch(nearbyActions.toggleRefreshingItems(true));
  dispatch(
    nearbyActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await nearbyAPI.getItems({
      category: categoryId,
      city: cityId,
      page: 1,
    });

    dispatch(nearbyActions.setPageItems(1));
    dispatch(nearbyActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        nearbyActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        nearbyActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(nearbyActions.toggleRefreshingItems(false));
  }
};

const getMoreItems = (categoryId) => async (dispatch, getState) => {
  const { nearby, profile } = getState();
  const { page, progress } = nearby;
  const cityId = profile.data.stayInfo?.hotel?.city?.id;

  if (progress.moreItems) return;

  dispatch(nearbyActions.toggleMoreItems(true));
  dispatch(
    nearbyActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await nearbyAPI.getItems({
      category: categoryId,
      city: cityId,
      page: page.items + 1,
    });

    dispatch(nearbyActions.incrementPageItems());
    dispatch(nearbyActions.setMoreItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        nearbyActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        nearbyActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(nearbyActions.toggleMoreItems(false));
  }
};

const setActiveCategory = (id) => async (dispatch) => {
  dispatch(nearbyActions.setActiveCategory(id));
};

const getItem = (id) => async (dispatch) => {
  dispatch(nearbyActions.togglePendingItem(true));
  dispatch(
    nearbyActions.setErrorItem({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await nearbyAPI.getItem(id);

    dispatch(nearbyActions.setItem(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        nearbyActions.setErrorItem({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        nearbyActions.setErrorItem({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(nearbyActions.togglePendingItem(false));
  }
};

const exportedObject = {
  getCategories,
  getItems,
  refreshItems,
  getMoreItems,
  setActiveCategory,
  getItem,
};

export default exportedObject;
