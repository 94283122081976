import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const containerMaxWidth = 480;

const theme = createTheme({
  palette: {
    white: '#FFFFFF',
    yellow: '#FFDF00',
    black: '#1A1A1C',
    red: '#E7125B',
    blue: '#00A5B0',
    lightBlue: '#ECF8F9',
    grey: '#616269',
    lightGrey: '#DCDDE3',
    lighterGrey: '#F2F2F2',
    border: '#CCCCCC',
  },
  spacing: (factor) => `${0.25 * factor}rem`,
  variables: {
    maxWidth: containerMaxWidth,
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
    body1: {
      fontSize: 16,
      lineHeight: 1.3,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          position: 'relative',
          fontSize: 16,
          lineHeight: 1.3,
          color: '#1A1A1C',
          backgroundColor: '#eaeaea',
          margin: '0 auto',
          maxWidth: theme.variables.maxWidth,
          fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
        },
      }),
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: '#E7125B',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          maxWidth: theme.variables.maxWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#1A1A1C',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          maxWidth: theme.variables.maxWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
        paper: {
          boxShadow: 'none',
        },
      },
    },
  },
});

const CustomThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export { CustomThemeProvider };
