import types from './types';

const togglePendingConfig = (payload, meta) => ({
  type: types.TOGGLE_PENDING_CONFIG,
  payload,
  meta,
});

const setConfig = (payload, meta) => ({
  type: types.SET_CONFIG,
  payload,
  meta,
});

const setErrorConfig = (payload, meta) => ({
  type: types.SET_ERROR_CONFIG,
  payload,
  meta,
});

const togglePendingStayInfo = (payload, meta) => ({
  type: types.TOGGLE_PENDING_STAY_INFO,
  payload,
  meta,
});

const setStayInfo = (payload, meta) => ({
  type: types.SET_STAY_INFO,
  payload,
  meta,
});

const setErrorStayInfo = (payload, meta) => ({
  type: types.SET_ERROR_STAY_INFO,
  payload,
  meta,
});

const togglePendingMenus = (payload, meta) => ({
  type: types.TOGGLE_PENDING_MENUS,
  payload,
  meta,
});

const setMenus = (payload, meta) => ({
  type: types.SET_MENUS,
  payload,
  meta,
});

const resetMenus = (payload, meta) => ({
  type: types.RESET_MENUS,
  payload,
  meta,
});

const setErrorMenus = (payload, meta) => ({
  type: types.SET_ERROR_MENUS,
  payload,
  meta,
});

const togglePendingUniqueId = (payload, meta) => ({
  type: types.TOGGLE_PENDING_UNIQUE_ID,
  payload,
  meta,
});

const setUniqueId = (payload, meta) => ({
  type: types.SET_UNIQUE_ID,
  payload,
  meta,
});

const setErrorUniqueId = (payload, meta) => ({
  type: types.SET_ERROR_UNIQUE_ID,
  payload,
  meta,
});

const togglePendingLogin = (payload, meta) => ({
  type: types.TOGGLE_PENDING_LOGIN,
  payload,
  meta,
});

const setLogin = (payload, meta) => ({
  type: types.SET_LOGIN,
  payload,
  meta,
});

const setErrorLogin = (payload, meta) => ({
  type: types.SET_ERROR_LOGIN,
  payload,
  meta,
});

const togglePendingRegister = (payload, meta) => ({
  type: types.TOGGLE_PENDING_REGISTER,
  payload,
  meta,
});

const setRegister = (payload, meta) => ({
  type: types.SET_REGISTER,
  payload,
  meta,
});

const setErrorRegister = (payload, meta) => ({
  type: types.SET_ERROR_REGISTER,
  payload,
  meta,
});

const togglePendingProfile = (payload, meta) => ({
  type: types.TOGGLE_PENDING_LOGIN,
  payload,
  meta,
});

const setProfile = (payload, meta) => ({
  type: types.SET_PROFILE,
  payload,
  meta,
});

const setErrorProfile = (payload, meta) => ({
  type: types.SET_ERROR_PROFILE,
  payload,
  meta,
});

const logout = (payload, meta) => ({
  type: types.LOGOUT,
  payload,
  meta,
});

const setLoader = (payload, meta) => ({
  type: types.SET_LOADER,
  payload,
  meta,
});

const exportedObject = {
  togglePendingConfig,
  setConfig,
  setErrorConfig,
  togglePendingStayInfo,
  setStayInfo,
  setErrorStayInfo,
  togglePendingMenus,
  setMenus,
  resetMenus,
  setErrorMenus,
  togglePendingUniqueId,
  setUniqueId,
  setErrorUniqueId,
  togglePendingLogin,
  setLogin,
  setErrorLogin,
  togglePendingProfile,
  setRegister,
  setErrorRegister,
  togglePendingRegister,
  setProfile,
  setErrorProfile,
  logout,
  setLoader,
};

export default exportedObject;
