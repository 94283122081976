import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  loader: true,
  config: {},
  stayInfo: null,
  menus: {
    list: [],
    count: 0,
    previous: null,
    next: null,
  },
  uniqueId: {
    id: null,
    token: null,
    user_id: null,
  },
  loginInfo: {
    access: null,
    refresh: null,
  },
  profile: {},
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case types.SET_CONFIG:
      return {
        ...state,
        config: payload,
      };
    case types.SET_STAY_INFO:
      return {
        ...state,
        stayInfo: payload,
      };
    case types.SET_MENUS:
      return {
        ...state,
        menus: {
          count: payload.count,
          list: payload.results,
          previous: payload.previous,
          next: payload.next,
        },
      };
    case types.RESET_MENUS:
      return {
        ...state,
        menus: defaultDataState.menus,
      };
    case types.SET_UNIQUE_ID:
      return {
        ...state,
        uniqueId: {
          id: payload.id,
          token: payload.token,
          user_id: payload.user_id,
        },
      };
    case types.SET_LOGIN:
      return {
        ...state,
        loginInfo: {
          access: payload.access,
          refresh: payload.refresh,
        },
      };
    case types.SET_PROFILE:
      return {
        ...state,
        profile: payload,
      };
    case types.LOGOUT:
      return {
        ...state,
        stayInfo: null,
        loginInfo: {
          id: null,
          access: null,
          refresh: null,
        },
        profile: {},
        menus: {
          list: [],
          count: 0,
          previous: null,
          next: null,
        },
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingConfig: false,
  pendingStayInfo: false,
  pendingMenus: false,
  pendingUniqueId: false,
  pendingLogin: false,
  pendingRegister: false,
  pendingProfile: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_CONFIG:
      return {
        ...state,
        pendingConfig: payload,
      };
    case types.TOGGLE_PENDING_STAY_INFO:
      return {
        ...state,
        pendingStayInfo: payload,
      };
    case types.TOGGLE_PENDING_MENUS:
      return {
        ...state,
        pendingMenus: payload,
      };
    case types.TOGGLE_PENDING_UNIQUE_ID:
      return {
        ...state,
        pendingUniqueId: payload,
      };
    case types.TOGGLE_PENDING_LOGIN:
      return {
        ...state,
        pendingLogin: payload,
      };
    case types.TOGGLE_PENDING_REGISTER:
      return {
        ...state,
        pendingRegister: payload,
      };
    case types.TOGGLE_PENDING_PROFILE:
      return {
        ...state,
        pendingProfile: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorConfig: {
    status: null,
    text: null,
  },
  errorStayInfo: {
    status: null,
    text: null,
  },
  errorMenus: {
    status: null,
    text: null,
  },
  errorUniqueId: {
    status: null,
    text: null,
  },
  errorLogin: {
    status: null,
    text: null,
  },
  errorRegister: {
    status: null,
    text: null,
  },
  errorProfile: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_CONFIG:
      return {
        ...state,
        errorConfig: payload,
      };
    case types.SET_ERROR_STAY_INFO:
      return {
        ...state,
        errorStayInfo: payload,
      };
    case types.SET_ERROR_MENUS:
      return {
        ...state,
        errorMenus: payload,
      };
    case types.SET_ERROR_UNIQUE_ID:
      return {
        ...state,
        errorUniqueId: payload,
      };
    case types.SET_ERROR_LOGIN:
      return {
        ...state,
        errorLogin: payload,
      };
    case types.SET_ERROR_REGISTER:
      return {
        ...state,
        errorRegister: payload,
      };
    case types.SET_ERROR_PROFILE:
      return {
        ...state,
        errorProfile: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  error,
});
