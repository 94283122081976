const TOGGLE_PENDING_CATEGORY = 'category/TOGGLE_PENDING_CATEGORY';
const TOGGLE_REFRESHING_CATEGORY = 'category/TOGGLE_REFRESHING_CATEGORY';
const SET_CATEGORY = 'category/SET_CATEGORY';
const SET_ERROR_CATEGORY = 'category/SET_ERROR_CATEGORY';

const exportedObject = {
  TOGGLE_PENDING_CATEGORY,
  TOGGLE_REFRESHING_CATEGORY,
  SET_CATEGORY,
  SET_ERROR_CATEGORY,
};

export default exportedObject;
