import vcategoryActions from './actions';

import vcategoryAPI from '../../api/vcategory';

const getVcategory = (id) => async (dispatch) => {
  dispatch(vcategoryActions.togglePendingVcategory(true));
  dispatch(
    vcategoryActions.setErrorVcategory({
      status: null,
      text: null,
    }),
  );

  try {
    const [info, services] = await Promise.all([
      vcategoryAPI
        .getVcategory({
          id: id,
        })
        .then((d) => d.data),
      vcategoryAPI
        .getVcategoryServices({
          vcategory: id,
        })
        .then((d) => d.data),
    ]);
    dispatch(vcategoryActions.setVcategory({ info, services }));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        vcategoryActions.setErrorVcategory({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        vcategoryActions.setErrorVcategory({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(vcategoryActions.togglePendingVcategory(false));
  }
};

const refreshVcategory = (id) => async (dispatch) => {
  dispatch(vcategoryActions.toggleRefreshingVcategory(true));
  dispatch(
    vcategoryActions.setErrorVcategory({
      status: null,
      text: null,
    }),
  );

  try {
    const [info, services] = await Promise.all([
      vcategoryAPI
        .getVcategory({
          id: id,
        })
        .then((d) => d.data),
      vcategoryAPI
        .getVcategoryServices({
          vcategory: id,
        })
        .then((d) => d.data),
    ]);
    dispatch(vcategoryActions.setVcategory({ info, services }));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        vcategoryActions.setErrorVcategory({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        vcategoryActions.setErrorVcategory({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(vcategoryActions.toggleRefreshingVcategory(false));
  }
};

const exportedObject = {
  getVcategory,
  refreshVcategory,
};

export default exportedObject;
