import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  landing: null,
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_LANDING:
      return {
        ...state,
        landing: payload,
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingLanding: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_STAYS:
      return {
        ...state,
        pendingLanding: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorLanding: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_LANDING:
      return {
        ...state,
        errorLanding: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  error,
});
