import orderActions from './actions';

import ordersAPI from '../../api/orders';

const getOrder = (id) => async (dispatch) => {
  dispatch(orderActions.togglePendingOrder(true));
  dispatch(
    orderActions.setErrorOrder({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await ordersAPI.getOrder({
      id: id,
    });
    dispatch(orderActions.setOrder(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        orderActions.setErrorOrder({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        orderActions.setErrorOrder({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(orderActions.togglePendingOrder(false));
  }
};

const refreshOrder = (id) => async (dispatch) => {
  dispatch(orderActions.toggleRefreshingOrder(true));
  dispatch(
    orderActions.setErrorOrder({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await ordersAPI.getOrder({
      id: id,
    });
    dispatch(orderActions.setOrder(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        orderActions.setErrorOrder({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        orderActions.setErrorOrder({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(orderActions.toggleRefreshingOrder(false));
  }
};

const cancelOrder = (id) => async (dispatch) => {
  dispatch(orderActions.togglePendingActionOrder(true));
  dispatch(
    orderActions.setErrorActionOrder({
      status: null,
      text: null,
    }),
  );

  try {
    const response = await ordersAPI.cancelOrder({
      id: id,
    });
    return response;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        orderActions.setErrorActionOrder({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        orderActions.setErrorActionOrder({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
    return response;
  } finally {
    dispatch(orderActions.togglePendingActionOrder(false));
  }
};

const getOrderFeedback = (id) => async (dispatch) => {
  dispatch(orderActions.togglePendingOrderFeedback(true));
  dispatch(
    orderActions.setErrorOrderFeedback({
      status: null,
      text: null,
    }),
  );

  try {
    const response = await ordersAPI.getOrderFeedback({
      id: id,
    });
    const { data, status } = response;

    if (status === 204) {
      dispatch(resetOrderFeedback());
    } else {
      dispatch(orderActions.setOrderFeedback(data.review));
    }
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        orderActions.setErrorOrder({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        orderActions.setErrorOrderFeedback({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(orderActions.togglePendingOrderFeedback(false));
  }
};

const resetOrderFeedback = () => async (dispatch) => {
  dispatch(orderActions.setOrderFeedback(false));
};

const sendOrderFeedback =
  (id, rating, comment = '') =>
  async () => {
    try {
      const response = await ordersAPI.sendOrderFeedback({
        id: id,
        rating: rating,
        comment: comment,
      });

      return response;
    } catch ({ response }) {
      return response;
    }
  };

const exportedObject = {
  getOrder,
  refreshOrder,
  cancelOrder,
  getOrderFeedback,
  resetOrderFeedback,
  sendOrderFeedback,
};

export default exportedObject;
