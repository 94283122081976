const SET_CONFIG = 'profile/SET_CONFIG';
const TOGGLE_PENDING_CONFIG = 'profile/TOGGLE_PENDING_CONFIG';
const SET_ERROR_CONFIG = 'profile/SET_ERROR_CONFIG';

const SET_STAY_INFO = 'profile/SET_STAY_INFO';
const TOGGLE_PENDING_STAY_INFO = 'profile/TOGGLE_PENDING_STAY_INFO';
const SET_ERROR_STAY_INFO = 'profile/SET_ERROR_STAY_INFO';

const SET_MENUS = 'profile/SET_MENUS';
const TOGGLE_PENDING_MENUS = 'profile/TOGGLE_PENDING_MENUS';
const SET_ERROR_MENUS = 'profile/SET_ERROR_MENUS';
const RESET_MENUS = 'profile/RESET_MENUS';

const SET_UNIQUE_ID = 'profile/SET_UNIQUE_ID';
const TOGGLE_PENDING_UNIQUE_ID = 'profile/TOGGLE_PENDING_UNIQUE_ID';
const SET_ERROR_UNIQUE_ID = 'profile/SET_ERROR_UNIQUE_ID';

const SET_LOGIN = 'profile/SET_LOGIN';
const TOGGLE_PENDING_LOGIN = 'profile/TOGGLE_PENDING_LOGIN';
const SET_ERROR_LOGIN = 'profile/SET_ERROR_LOGIN';

const SET_REGISTER = 'profile/SET_REGISTER';
const TOGGLE_PENDING_REGISTER = 'profile/TOGGLE_PENDING_REGISTER';
const SET_ERROR_REGISTER = 'profile/SET_ERROR_REGISTER';

const SET_PROFILE = 'profile/SET_PROFILE';
const TOGGLE_PENDING_PROFILE = 'profile/TOGGLE_PENDING_PROFILE';
const SET_ERROR_PROFILE = 'profile/SET_ERROR_PROFILE';

const LOGOUT = 'profile/LOGOUT';

const SET_LOADER = 'profile/SET_LOADER';

const exportedObject = {
  SET_CONFIG,
  TOGGLE_PENDING_CONFIG,
  SET_ERROR_CONFIG,
  SET_STAY_INFO,
  TOGGLE_PENDING_STAY_INFO,
  SET_ERROR_STAY_INFO,
  SET_MENUS,
  RESET_MENUS,
  TOGGLE_PENDING_MENUS,
  SET_ERROR_MENUS,
  SET_UNIQUE_ID,
  TOGGLE_PENDING_UNIQUE_ID,
  SET_ERROR_UNIQUE_ID,
  SET_LOGIN,
  TOGGLE_PENDING_LOGIN,
  SET_ERROR_LOGIN,
  SET_REGISTER,
  TOGGLE_PENDING_REGISTER,
  SET_ERROR_REGISTER,
  SET_PROFILE,
  TOGGLE_PENDING_PROFILE,
  SET_ERROR_PROFILE,
  LOGOUT,
  SET_LOADER,
};

export default exportedObject;
