import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  appProps: {},
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_APP_PROPS:
      return {
        ...state,
        appProps: payload,
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingAppProps: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_APP_PROPS:
      return {
        ...state,
        pendingAppProps: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorAppProps: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_APP_PROPS:
      return {
        ...state,
        errorAppProps: payload,
      };
    default:
      return state;
  }
};
export default combineReducers({
  data,
  progress,
  error,
});
