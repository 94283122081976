import types from './types';

const togglePendingOrders = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ORDERS,
  payload,
  meta,
});

const toggleRefreshingOrders = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_ORDERS,
  payload,
  meta,
});

const toggleMoreOrders = (payload, meta) => ({
  type: types.TOGGLE_MORE_ORDERS,
  payload,
  meta,
});

const setOrders = (payload, meta) => ({
  type: types.SET_ORDERS,
  payload,
  meta,
});

const setMoreOrders = (payload, meta) => ({
  type: types.SET_MORE_ORDERS,
  payload,
  meta,
});

const setErrorOrders = (payload, meta) => ({
  type: types.SET_ERROR_ORDERS,
  payload,
  meta,
});

const setPageOrders = (payload, meta) => ({
  type: types.SET_PAGE_ORDERS,
  payload,
  meta,
});

const incrementPageOrders = (meta) => ({
  type: types.INCREMENT_PAGE_ORDERS,
  meta,
});

const decrementPageOrders = (meta) => ({
  type: types.DECREMENT_PAGE_ORDERS,
  meta,
});

const exportedObject = {
  togglePendingOrders,
  toggleRefreshingOrders,
  toggleMoreOrders,
  setOrders,
  setMoreOrders,
  setErrorOrders,
  setPageOrders,
  incrementPageOrders,
  decrementPageOrders,
};

export default exportedObject;
