import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  categories: [],
  items: {
    list: [],
    next: null,
    previous: null,
    count: 0,
  },
  item: {},
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_ITEMS:
      return {
        ...state,
        items: {
          count: payload.count,
          previous: payload.previous,
          next: payload.next,
          list: payload.results,
        },
      };
    case types.SET_MORE_ITEMS:
      return {
        ...state,
        items: {
          count: payload.count,
          previous: payload.previous,
          next: payload.next,
          list: [...state.items.list, ...payload.results],
        },
      };
    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: [...payload.results],
      };
    case types.SET_ITEM:
      return {
        ...state,
        item: payload,
      };
    default:
      return state;
  }
};

const defaultPageState = {
  items: 0,
};

const page = (state = defaultPageState, { type, payload }) => {
  switch (type) {
    case types.SET_PAGE_ITEMS:
      return {
        ...state,
        items: payload,
      };
    case types.INCREMENT_PAGE_ITEMS:
      return {
        ...state,
        items: state.items + 1,
      };
    case types.DECREMENT_PAGE_ITEMS:
      return {
        ...state,
        items: state.items - 1,
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingItems: false,
  refreshingItems: false,
  moreItems: false,
  pendingCategories: false,
  pendingItem: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_ITEMS:
      return {
        ...state,
        pendingItems: payload,
      };
    case types.TOGGLE_REFRESHING_ITEMS:
      return {
        ...state,
        refreshingItems: payload,
      };
    case types.TOGGLE_MORE_ITEMS:
      return {
        ...state,
        moreItems: payload,
      };
    case types.TOGGLE_PENDING_CATEGORIES:
      return {
        ...state,
        pendingCategories: payload,
      };
    case types.TOGGLE_PENDING_ITEM:
      return {
        ...state,
        pendingItem: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorItems: {
    status: null,
    text: null,
  },
  errorCategories: {
    status: null,
    text: null,
  },
  errorItem: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_ITEMS:
      return {
        ...state,
        errorItems: payload,
      };
    case types.SET_ERROR_CATEGORIES:
      return {
        ...state,
        errorCategories: payload,
      };
    case types.SET_ERROR_ITEM:
      return {
        ...state,
        errorItem: payload,
      };
    default:
      return state;
  }
};

const defaultActiveState = {
  category: null,
};

const active = (state = defaultActiveState, { type, payload }) => {
  switch (type) {
    case types.SET_ACTIVE_CATEGORY:
      return {
        ...state,
        category: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  page,
  error,
  active,
});
