const TOGGLE_PENDING_SERVICE = 'menu/TOGGLE_PENDING_SERVICE';
const TOGGLE_REFRESHING_SERVICE = 'menu/TOGGLE_REFRESHING_SERVICE';
const SET_SERVICE = 'menu/SET_SERVICE';
const SET_ERROR_SERVICE = 'menu/SET_ERROR_SERVICE';
const SET_ITEM = 'menu/SET_ITEM';
const TOGGLE_PENDING_ITEM = 'menu/TOGGLE_PENDING_ITEM';
const SET_ERROR_ITEM = 'menu/SET_ERROR_ITEM';
const SET_CART_ITEM = 'menu/SET_CART_ITEM';

const exportedObject = {
  TOGGLE_PENDING_SERVICE,
  TOGGLE_REFRESHING_SERVICE,
  SET_SERVICE,
  SET_ERROR_SERVICE,
  SET_ITEM,
  TOGGLE_PENDING_ITEM,
  SET_ERROR_ITEM,
  SET_CART_ITEM,
};

export default exportedObject;
