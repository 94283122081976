import roomActions from './actions';
import roomAPI from '../../api/room';
import isEmpty from 'lodash/isEmpty';

const getItems = () => async (dispatch, getState) => {
  const { room, profile } = getState();
  const { progress } = room;
  const { menus } = profile.data;

  if (progress.pendingItems) return;

  let menuId;
  if (menus.list) {
    let menuObj = menus.list.find((a) => a.type === 'room');
    menuId = menuObj && menuObj.id;
  }

  if (!menuId) {
    dispatch(roomActions.setPageItems(1));
    dispatch(roomActions.resetItems());
    return;
  }

  dispatch(roomActions.togglePendingItems(true));
  dispatch(
    roomActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await roomAPI.getItems({
      page: 1,
      menu: menuId,
    });

    dispatch(roomActions.setPageItems(1));
    dispatch(roomActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        roomActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        roomActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(roomActions.togglePendingItems(false));
  }
};

const refreshItems = () => async (dispatch, getState) => {
  const { room, profile } = getState();
  const { progress } = room;
  const { menus } = profile.data;

  if (progress.refreshingItems) return;

  let menuId;
  if (menus.list) {
    let menuObj = menus.list.find((a) => a.type === 'room');
    menuId = menuObj && menuObj.id;
  }

  if (!menuId) {
    dispatch(roomActions.setPageItems(1));
    dispatch(roomActions.resetItems());
    return;
  }

  dispatch(roomActions.toggleRefreshingItems(true));
  dispatch(
    roomActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await roomAPI.getItems({
      page: 1,
      menu: menuId,
    });

    dispatch(roomActions.setPageItems(1));
    dispatch(roomActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        roomActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        roomActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(roomActions.toggleRefreshingItems(false));
  }
};

const getMoreItems = () => async (dispatch, getState) => {
  const { room, profile } = getState();
  const { page, progress } = room;
  const { menus } = profile.data;

  if (progress.moreItems) return;

  let menuId;
  if (menus.list) {
    let menuObj = menus.list.find((a) => a.type === 'room');
    menuId = menuObj && menuObj.id;
  }

  if (!menuId) {
    dispatch(roomActions.setPageItems(1));
    dispatch(roomActions.resetItems());
    return;
  }

  dispatch(roomActions.toggleMoreItems(true));
  dispatch(
    roomActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await roomAPI.getItems({
      page: page + 1,
      menu: menuId,
    });

    dispatch(roomActions.incrementPageItems());
    dispatch(roomActions.setMoreItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        roomActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        roomActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(roomActions.toggleMoreItems(false));
  }
};

const getImages = () => async (dispatch, getState) => {
  const { room, profile } = getState();
  const { progress } = room;
  const stayRoom = profile.data.stayInfo?.room;
  const hotelInfo = profile.data.stayInfo?.hotel;

  const defaultImageObj = profile.data.config.gallery?.defaultImages?.find((a) => a.type === 'room');

  if (progress.pendingImages) return;

  dispatch(roomActions.togglePendingImages(true));
  dispatch(
    roomActions.setErrorImages({
      status: null,
      text: null,
    }),
  );

  try {
    let response;
    if (stayRoom?.id) {
      response = await roomAPI.getRoomImages(stayRoom.id);
    } else {
      response = await roomAPI.getHotelImages(hotelInfo.id);
    }
    let results = response.data?.results;
    if (isEmpty(results) && defaultImageObj) {
      results.push(defaultImageObj);
    }
    dispatch(roomActions.setImages(results));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        roomActions.setErrorImages({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        roomActions.setErrorImages({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(roomActions.togglePendingImages(false));
  }
};

const exportedObject = {
  getItems,
  refreshItems,
  getMoreItems,
  getImages,
};

export default exportedObject;
