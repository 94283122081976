// ** React
import { useEffect, useState, lazy, Suspense } from 'react';

// ** Package Json
import packageInfo from '../package.json';

// ** Components
import WebSocketClient from 'src/components/WebsocketClient';

// ** Constants
import { PROJECT } from 'src/constants';

// ** Modals
import UpdateModal from 'src/modals/UpdateModal';
import InitialErrorModal from 'src/modals/InitialErrorModal';
import SplashModal from 'src/modals/SplashModal';

// ** Redux
import { connect } from 'react-redux';
import { appOperations } from 'src/store/app';
import { profileOperations } from 'src/store/profile';
import { helpersOperations } from 'src/store/helpers';
import { landingsOperations } from 'src/store/landings';

// ** Context
import { useGeo } from 'src/context/geo-context';

// ** Config
import { getFirebaseToken } from 'src/config/firebase';

// ** Language
import { useTranslation } from 'react-i18next';

const loadMain = () => import('src/Main');
const Main = lazy(loadMain);

const Notifications = ({ registerFcmDevice, profile }) => {
  const [fcmToken, setFcmToken] = useState(false);

  useEffect(() => {
    getFirebaseToken(setFcmToken);
  }, []);

  useEffect(() => {
    if (!fcmToken || !profile.uniqueId.id) return;

    registerFcmDevice({
      registrationId: fcmToken,
      deviceId: profile.uniqueId.id,
      type: 'web',
    });
  }, [fcmToken, profile.uniqueId?.id, profile.profile?.id]);

  return null;
};

const Bootstrapper = ({
  profile,
  getAppProps,
  getConfig,
  getLoginInfo,
  getStayInfo,
  getUniqueId,
  getOrderStatuses,
  getMessagesTypes,
  getLanguages,
  checkUpdate,
  setLoader,
  registerFcmDevice,
  getLanding,
}) => {
  const { i18n } = useTranslation();
  const { resolvedLanguage: language } = i18n;
  const { getCurrentPosition } = useGeo();

  const [loaded, setLoaded] = useState(false);
  const [userChecked, setUserChecked] = useState(false);
  const [userCheckedError, setUserCheckedError] = useState(false);

  const [hasAnimationPlayedOnce, setHasAnimationPlayedOnce] = useState(false);

  const checkUserInfo = async () => {
    await getUniqueId().then(async () => {
      await getLoginInfo()
        .then(async () => {
          setUserCheckedError(false);
          setUserChecked(true);
        })
        .catch(() => {
          setUserCheckedError(true);
        });
    });
  };

  const checkHotelData = async () => {
    await getConfig().then(async () => {
      await getStayInfo().then(async () => {
        await getLanding();
      });
    });
  };

  const getHelpers = async () => {
    await getOrderStatuses(language);
    await getLanguages(language);
    await getMessagesTypes(language);
  };

  const getInfo = async () => {
    await checkUpdate({
      platform: 'web',
      version: packageInfo.version,
    });
    await checkUserInfo();
  };

  const getMainData = async () => {
    await getAppProps(PROJECT).then(async () => {
      await checkHotelData();
    });
    setLoaded(true);
  };

  useEffect(() => {
    getHelpers();
  }, [language]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (!userChecked) return;

    getMainData();
  }, [profile.stayInfo?.hotel?.id, language, userChecked]);

  useEffect(() => {
    let value = true;
    if (hasAnimationPlayedOnce) {
      if (userCheckedError) {
        value = false;
      } else {
        value = !(loaded && hasAnimationPlayedOnce);
      }
    }
    setLoader(value);
  }, [loaded, userCheckedError, hasAnimationPlayedOnce]);

  useEffect(() => {
    getCurrentPosition();
  }, []);

  return (
    <Suspense fallback={null}>
      <WebSocketClient>
        {loaded ? <Main /> : null}
        <UpdateModal />
      </WebSocketClient>
      <InitialErrorModal error={userCheckedError} retryFn={checkUserInfo} />
      <SplashModal project={PROJECT} setHasAnimationPlayedOnce={setHasAnimationPlayedOnce} />
      <Notifications registerFcmDevice={registerFcmDevice} profile={profile} />
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.data,
});

const mapDispatchToProps = {
  getStayInfo: profileOperations.getStayInfo,
  getLoginInfo: profileOperations.getLoginInfo,
  getUniqueId: profileOperations.getUniqueId,
  getConfig: profileOperations.getConfig,
  setLoader: profileOperations.setLoader,
  registerFcmDevice: profileOperations.registerFcmDevice,
  getOrderStatuses: helpersOperations.getOrderStatuses,
  getMessagesTypes: helpersOperations.getMessagesTypes,
  getLanguages: helpersOperations.getLanguages,
  checkUpdate: helpersOperations.checkUpdate,
  getLanding: landingsOperations.getLanding,
  getAppProps: appOperations.getAppProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bootstrapper);
