const SET_NOTIFICATIONS = 'notifications/SET_NOTIFICATIONS';
const CLEAR_NOTIFICATIONS = 'notifications/CLEAR_NOTIFICATIONS';
const SET_COUNT_NOTIFICATIONS = 'notifications/SET_COUNT_NOTIFICATIONS';
const SET_ERROR_MESSAGE = 'notifications/SET_ERROR_MESSAGE';

const SET_NOTIFICATIONS_GROUPS = 'notifications/SET_NOTIFICATIONS_GROUPS';
const TOGGLE_PENDING_NOTIFICATIONS_GROUPS = 'notifications/TOGGLE_PENDING_NOTIFICATIONS_GROUPS';
const SET_ERROR_NOTIFICATIONS_GROUPS = 'notifications/SET_ERROR_NOTIFICATIONS_GROUPS';

const exportedObject = {
  SET_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  SET_COUNT_NOTIFICATIONS,
  SET_ERROR_MESSAGE,
  SET_NOTIFICATIONS_GROUPS,
  TOGGLE_PENDING_NOTIFICATIONS_GROUPS,
  SET_ERROR_NOTIFICATIONS_GROUPS,
};

export default exportedObject;
