import types from './types';

const togglePendingCategory = (payload, meta) => ({
  type: types.TOGGLE_PENDING_CATEGORY,
  payload,
  meta,
});

const toggleRefreshingCategory = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_CATEGORY,
  payload,
  meta,
});

const setCategory = (payload, meta) => ({
  type: types.SET_CATEGORY,
  payload,
  meta,
});

const setErrorCategory = (payload, meta) => ({
  type: types.SET_ERROR_CATEGORY,
  payload,
  meta,
});

const exportedObject = {
  togglePendingCategory,
  toggleRefreshingCategory,
  setCategory,
  setErrorCategory,
};

export default exportedObject;
