const SET_ORDERS = 'orders/SET_ORDERS';
const SET_MORE_ORDERS = 'orders/SET_MORE_ORDERS';
const TOGGLE_PENDING_ORDERS = 'orders/TOGGLE_PENDING_ORDERS';
const TOGGLE_REFRESHING_ORDERS = 'orders/TOGGLE_REFRESH_ORDERS';
const TOGGLE_MORE_ORDERS = 'orders/TOGGLE_MORE_ORDERS';
const SET_ERROR_ORDERS = 'orders/SET_ERROR_ORDERS';
const SET_PAGE_ORDERS = 'orders/SET_PAGE_ORDERS';
const INCREMENT_PAGE_ORDERS = 'orders/INCREMENT_PAGE_ORDERS';
const DECREMENT_PAGE_ORDERS = 'orders/DECREMENT_PAGE_ORDERS';

const exportedObject = {
  SET_ORDERS,
  SET_MORE_ORDERS,
  TOGGLE_PENDING_ORDERS,
  TOGGLE_REFRESHING_ORDERS,
  TOGGLE_MORE_ORDERS,
  SET_ERROR_ORDERS,
  SET_PAGE_ORDERS,
  INCREMENT_PAGE_ORDERS,
  DECREMENT_PAGE_ORDERS,
};

export default exportedObject;
