import types from './types';

const togglePendingAppProps = (payload, meta) => ({
  type: types.TOGGLE_PENDING_APP_PROPS,
  payload,
  meta,
});

const setAppProps = (payload, meta) => ({
  type: types.SET_APP_PROPS,
  payload,
  meta,
});

const setErrorAppProps = (payload, meta) => ({
  type: types.SET_ERROR_APP_PROPS,
  payload,
  meta,
});

const exportedObject = {
  togglePendingAppProps,
  setAppProps,
  setErrorAppProps,
};

export default exportedObject;
