import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import * as reducers from '../store';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile', 'helpers'],
};

const initialState = {};

const rootReducer = combineReducers({
  ...reducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: () => {
    return [thunk];
  },
});

export default store;

export const persistor = persistStore(store);
