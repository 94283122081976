import { useState, useContext, createContext } from 'react';

const GeoContext = createContext();

const GeoProvider = ({ children }) => {
  const settings = {
    enableHighAccuracy: true,
    timeout: Infinity,
    maximumAge: 0,
  };

  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords, timestamp }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    });
  };

  const onError = (error) => {
    setError(error.message);
  };

  const getCurrentPosition = () => {
    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }

    navigator.geolocation.getCurrentPosition(onChange, onError, settings);
  };

  return (
    <GeoContext.Provider
      value={{
        data: {
          ...position,
          error,
        },
        getCurrentPosition,
      }}
    >
      {children}
    </GeoContext.Provider>
  );
};

const useGeo = () => {
  const context = useContext(GeoContext);

  if (context === undefined) {
    throw new Error('useGeo must be used within a GeoProvider');
  }
  return context;
};

export { GeoProvider, useGeo };
