import types from './types';

const togglePendingCategories = (payload, meta) => ({
  type: types.TOGGLE_PENDING_CATEGORIES,
  payload,
  meta,
});

const setCategories = (payload, meta) => ({
  type: types.SET_CATEGORIES,
  payload,
  meta,
});

const setErrorCategories = (payload, meta) => ({
  type: types.SET_ERROR_CATEGORIES,
  payload,
  meta,
});

const togglePendingItems = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ITEMS,
  payload,
  meta,
});

const toggleRefreshingItems = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_ITEMS,
  payload,
  meta,
});

const toggleMoreItems = (payload, meta) => ({
  type: types.TOGGLE_MORE_ITEMS,
  payload,
  meta,
});

const setItems = (payload, meta) => ({
  type: types.SET_ITEMS,
  payload,
  meta,
});

const setMoreItems = (payload, meta) => ({
  type: types.SET_MORE_ITEMS,
  payload,
  meta,
});

const setErrorItems = (payload, meta) => ({
  type: types.SET_ERROR_ITEMS,
  payload,
  meta,
});

const setActiveCategory = (payload, meta) => ({
  type: types.SET_ACTIVE_CATEGORY,
  payload,
  meta,
});

const togglePendingItem = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ITEM,
  payload,
  meta,
});

const setItem = (payload, meta) => ({
  type: types.SET_ITEM,
  payload,
  meta,
});

const setErrorItem = (payload, meta) => ({
  type: types.SET_ERROR_ITEM,
  payload,
  meta,
});

const setPageItems = (payload, meta) => ({
  type: types.SET_PAGE_ITEMS,
  payload,
  meta,
});

const incrementPageItems = (meta) => ({
  type: types.INCREMENT_PAGE_ITEMS,
  meta,
});

const decrementPageItems = (meta) => ({
  type: types.DECREMENT_PAGE_ITEMS,
  meta,
});

const exportedObject = {
  togglePendingCategories,
  setCategories,
  setErrorCategories,
  togglePendingItems,
  toggleRefreshingItems,
  toggleMoreItems,
  setItems,
  setMoreItems,
  setErrorItems,
  setActiveCategory,
  togglePendingItem,
  setItem,
  setErrorItem,
  setPageItems,
  incrementPageItems,
  decrementPageItems,
};

export default exportedObject;
