import types from './types';

const togglePendingOrder = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ORDER,
  payload,
  meta,
});

const toggleRefreshingOrder = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_ORDER,
  payload,
  meta,
});

const togglePendingActionOrder = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ACTION_ORDER,
  payload,
  meta,
});

const setOrder = (payload, meta) => ({
  type: types.SET_ORDER,
  payload,
  meta,
});

const setErrorOrder = (payload, meta) => ({
  type: types.SET_ERROR_ORDER,
  payload,
  meta,
});

const setErrorActionOrder = (payload, meta) => ({
  type: types.SET_ERROR_ACTION_ORDER,
  payload,
  meta,
});

const togglePendingOrderFeedback = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ORDER_FEEDBACK,
  payload,
  meta,
});

const setOrderFeedback = (payload, meta) => ({
  type: types.SET_ORDER_FEEDBACK,
  payload,
  meta,
});

const setErrorOrderFeedback = (payload, meta) => ({
  type: types.SET_ERROR_ORDER_FEEDBACK,
  payload,
  meta,
});

const exportedObject = {
  togglePendingOrder,
  toggleRefreshingOrder,
  togglePendingActionOrder,
  setOrder,
  setErrorOrder,
  setErrorActionOrder,
  togglePendingOrderFeedback,
  setOrderFeedback,
  setErrorOrderFeedback,
};

export default exportedObject;
