// Context
import { CustomThemeProvider } from './theme-context';
import { LayoutProvider } from './layout-context';
import { GeoProvider } from './geo-context';

const AppProviders = ({ children }) => {
  return (
    <CustomThemeProvider>
      <LayoutProvider>
        <GeoProvider>{children}</GeoProvider>
      </LayoutProvider>
    </CustomThemeProvider>
  );
};

export { AppProviders };
