import landingsActions from './actions';

import landingsAPI from '../../api/landings';

import first from 'lodash/first';

const getLanding = () => async (dispatch, getState) => {
  const { landings, profile } = getState();
  const { progress } = landings;

  const stayInfo = profile.data.stayInfo;

  if (progress.pendingLanding || !stayInfo) return;

  dispatch(landingsActions.togglePendingLanding(true));
  dispatch(
    landingsActions.setErrorLanding({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await landingsAPI.getLandings();
    const result = first(data.results) || false;

    dispatch(landingsActions.setLanding(result));

    return result;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        landingsActions.setErrorLanding({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        landingsActions.setErrorLanding({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(landingsActions.togglePendingLanding(false));
  }
};

const exportedObject = {
  getLanding,
};

export default exportedObject;
