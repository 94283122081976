import { POST_TRANSLATE_MESSAGE, GET_NOTIFICATIONS_GROUPS } from 'src/constants';

import { http } from 'src/helpers/http';

export const translateMessage = async ({ id, language }) => {
  const response = await http.post(POST_TRANSLATE_MESSAGE.replace(':id', id), {
    destination_language: language,
  });

  return response;
};

export const getNotificationsGroups = async () => {
  const response = await http.get(GET_NOTIFICATIONS_GROUPS);

  return response;
};

const exportedObject = {
  translateMessage,
  getNotificationsGroups,
};

export default exportedObject;
