import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  info: {},
  orderFeedback: false,
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_ORDER:
      return {
        ...state,
        info: payload,
      };
    case types.SET_ORDER_FEEDBACK:
      return {
        ...state,
        orderFeedback: payload,
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pending: false,
  refreshing: false,
  pendingAction: false,
  pendingOrderFeedback: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_ORDER:
      return {
        ...state,
        pending: payload,
      };
    case types.TOGGLE_REFRESHING_ORDER:
      return {
        ...state,
        refreshing: payload,
      };
    case types.TOGGLE_PENDING_ACTION_ORDER:
      return {
        ...state,
        pendingAction: payload,
      };
    case types.TOGGLE_PENDING_ORDER_FEEDBACK:
      return {
        ...state,
        pendingOrderFeedback: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorMain: {
    status: null,
    text: null,
  },
  errorAction: {
    status: null,
    text: null,
  },
  errorOrderFeedback: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_ORDER:
      return {
        ...state,
        errorMain: payload,
      };
    case types.SET_ERROR_ACTION_ORDER:
      return {
        ...state,
        errorAction: payload,
      };
    case types.SET_ERROR_ORDER_FEEDBACK:
      return {
        ...state,
        errorOrderFeedback: payload,
      };
    default:
      return state;
  }
};
export default combineReducers({
  data,
  progress,
  error,
});
