import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  list: [],
  next: null,
  previous: null,
  count: 0,
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_STAYS:
      return {
        ...state,
        count: payload.count,
        previous: payload.previous,
        next: payload.next,
        list: payload.results,
      };
    case types.SET_MORE_STAYS:
      return {
        ...state,
        count: payload.count,
        previous: payload.previous,
        next: payload.next,
        list: [...state.list, ...payload.results],
      };
    default:
      return state;
  }
};

const defaultPageState = 0;

const page = (state = defaultPageState, { type, payload }) => {
  switch (type) {
    case types.SET_PAGE_STAYS:
      return payload;
    case types.INCREMENT_PAGE_STAYS:
      return state + 1;
    case types.DECREMENT_PAGE_STAYS:
      return state - 1;
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingStays: false,
  refreshingStays: false,
  moreStays: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_STAYS:
      return {
        ...state,
        pendingStays: payload,
      };
    case types.TOGGLE_REFRESHING_STAYS:
      return {
        ...state,
        refreshingStays: payload,
      };
    case types.TOGGLE_MORE_STAYS:
      return {
        ...state,
        moreStays: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorStays: {
    status: null,
    text: null,
  },
  errorHotel: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_STAYS:
      return {
        ...state,
        errorStays: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  error,
  page,
});
