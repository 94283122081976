import { LS_TOKEN } from 'src/constants';

export const getData = (key) => {
  const jsonValue = window.localStorage.getItem(LS_TOKEN + '_' + key);
  return jsonValue != null ? JSON.parse(jsonValue) : null;
};

export const setData = (key, value) => {
  const jsonValue = JSON.stringify(value);
  window.localStorage.setItem(LS_TOKEN + '_' + key, jsonValue);
};

export const removeData = (key) => {
  return window.localStorage.removeItem(LS_TOKEN + '_' + key);
};

const exportedObject = {
  getData,
  setData,
  removeData,
};

export default exportedObject;
