import {
  GET_ORDER_STATUSES,
  GET_GEO_LEVELS,
  GET_MESSAGES_TYPES,
  GET_LANGUAGES,
  POST_UPDATE,
  GET_SYSTEM,
} from 'src/constants';

import { http } from 'src/helpers/http';

export const getOrderStatuses = async () => {
  const response = await http.get(GET_ORDER_STATUSES);

  return response;
};

export const getGeoLevels = async () => {
  const response = await http.get(GET_GEO_LEVELS);

  return response;
};

export const getMessagesTypes = async () => {
  const response = await http.get(GET_MESSAGES_TYPES);

  return response;
};

export const getLanguages = async () => {
  const response = await http.get(GET_LANGUAGES);

  return response;
};

export const checkUpdate = async ({ version, platform }) => {
  const response = await http.post(POST_UPDATE, {
    version: version,
    platform: platform,
  });

  return response;
};

export const getSystem = async () => {
  const response = await http.get(GET_SYSTEM);

  return response;
};

const exportedObject = {
  getOrderStatuses,
  getGeoLevels,
  getMessagesTypes,
  getLanguages,
  checkUpdate,
  getSystem,
};

export default exportedObject;
