// ** React
import { useState, useEffect } from 'react';

// ** MUI
import { Dialog, Box, ButtonBase, Typography } from '@mui/material';

// ** Language
import { useTranslation } from 'react-i18next';

// ** Components
import ContentView from 'src/components/ContentView';
import ScrollView from 'src/components/ScrollView';
import Logo from 'src/components/Logo';

// ** Constants
import { PROJECT } from 'src/constants';

// ** Assets
import CrossIcon from 'src/components/svg/CrossIcon';
import UpdateIcon from 'src/components/svg/UpdateIcon';

// ** Redux
import { connect } from 'react-redux';

const classes = {
  dialog: {
    zIndex: '1400 !important',
    '& .MuiDialog-paper': {
      backgroundColor: 'yellow',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
  main: {
    paddingTop: '16px',
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 16,
    opacity: 0.5,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  mainWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    marginBottom: '26px',
  },
  textWrapper: {
    marginBottom: '16px',
  },
  text: {
    textAlign: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  updateButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 56,
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: 'red',
    borderRadius: '12px',
  },
  updateButtonText: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: '600',
    color: 'white',
  },
};

const UpdateModal = ({ update }) => {
  const { t: translation } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleClose = () => setVisible(false);

  const handleUpdatePress = () => {
    caches
      .keys()
      .then((names) => {
        for (let name of names) {
          caches.delete(name);
        }
      })
      .then(() => {
        const form = document.createElement('form');
        form.method = 'GET';
        form.action = window.location.href;
        document.body.appendChild(form);
        form.submit();

        window.location.href = './';
      });
  };

  useEffect(() => {
    const value = !!(update.shouldUpdate || update.mustUpdate);
    setVisible(value);
  }, [update]);

  return (
    <Dialog open={visible} fullScreen sx={classes.dialog} disableEscapeKeyDown>
      <ContentView>
        <ScrollView>
          <Box sx={classes.main}>
            {!update.mustUpdate ? (
              <ButtonBase onClick={handleClose} sx={classes.close}>
                <CrossIcon width={28} height={28} />
              </ButtonBase>
            ) : null}
            <Box sx={classes.logoWrapper}>
              <Logo project={PROJECT} size={92} />
            </Box>
            <Box sx={classes.mainWrapper}>
              <Box sx={classes.iconWrapper}>
                <UpdateIcon width={188} height={164} />
              </Box>
              <Box sx={classes.textWrapper}>
                <Typography sx={classes.text}>{translation('needToUpdate')}</Typography>
              </Box>
            </Box>
            <Box sx={classes.buttonsWrapper}>
              <ButtonBase sx={classes.updateButton} onClick={handleUpdatePress}>
                <Typography sx={classes.updateButtonText}>{translation('update')}</Typography>
              </ButtonBase>
            </Box>
          </Box>
        </ScrollView>
      </ContentView>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  update: state.helpers.data.update,
});

export default connect(mapStateToProps, null)(UpdateModal);
