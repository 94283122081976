import staysActions from './actions';

import staysAPI from '../../api/stays';

const getStays = () => async (dispatch, getState) => {
  const { stays } = getState();
  const { progress } = stays;

  if (progress.pendingStays) return;

  dispatch(staysActions.togglePendingStays(true));
  dispatch(
    staysActions.setErrorStays({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await staysAPI.getStays({ page: 1 });

    dispatch(staysActions.setPageStays(1));
    dispatch(staysActions.setStays(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        staysActions.setErrorStays({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        staysActions.setErrorStays({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(staysActions.togglePendingStays(false));
  }
};

const refreshStays = () => async (dispatch, getState) => {
  const { stays } = getState();
  const { progress } = stays;

  if (progress.refreshingStays) return;

  dispatch(staysActions.toggleRefreshingStays(true));
  dispatch(
    staysActions.setErrorStays({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await staysAPI.getStays({ page: 1 });

    dispatch(staysActions.setPageStays(1));
    dispatch(staysActions.setStays(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        staysActions.setErrorStays({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        staysActions.setErrorStays({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(staysActions.toggleRefreshingStays(false));
  }
};

const getMoreStays = () => async (dispatch, getState) => {
  const { stays } = getState();
  const { page, progress } = stays;

  if (progress.moreStays) return;

  dispatch(staysActions.toggleMoreStays(true));
  dispatch(
    staysActions.setErrorStays({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await staysAPI.getStays({ page: page + 1 });

    dispatch(staysActions.incrementPageStays());
    dispatch(staysActions.setMoreStays(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        staysActions.setErrorStays({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        staysActions.setErrorStays({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(staysActions.toggleMoreStays(false));
  }
};

const getHotelByCode =
  ({ code }) =>
  async (dispatch) => {
    dispatch(staysActions.togglePendingHotel(true));
    dispatch(
      staysActions.setErrorHotel({
        status: null,
        text: null,
      }),
    );

    try {
      const response = await staysAPI.getHotels({
        code: code,
      });

      return response;
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          staysActions.setErrorHotel({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          staysActions.setErrorHotel({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
      return response;
    } finally {
      dispatch(staysActions.togglePendingHotel(false));
    }
  };

const exportedObject = {
  getStays,
  refreshStays,
  getMoreStays,
  getHotelByCode,
};

export default exportedObject;
