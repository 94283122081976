// ** MUI
import Box from '@mui/material/Box';

// ** Bootstrapper
import Bootstrapper from 'src/Bootstrapper';

// ** Context
import { AppProviders } from 'src/context';

// ** Store
import { Provider } from 'react-redux';
import store from 'src/config/store';

// ** Config
import DatePickerWrapper from 'src/libs/reactDatePicker';

// ** http
import { httpInterceptor } from 'src/helpers/http';
httpInterceptor(store);

const App = () => {
  return (
    <AppProviders>
      <Provider store={store}>
        <Bootstrapper />

        <DatePickerWrapper sx={{ zIndex: 11 }}>
          <Box id={'react-datepicker-portal'} />
        </DatePickerWrapper>
      </Provider>
    </AppProviders>
  );
};

export default App;
