const TOGGLE_PENDING_SERVICE = 'service/TOGGLE_PENDING_SERVICE';
const TOGGLE_REFRESHING_SERVICE = 'service/TOGGLE_REFRESHING_SERVICE';
const SET_SERVICE = 'service/SET_SERVICE';
const SET_ERROR_SERVICE = 'service/SET_ERROR_SERVICE';

const exportedObject = {
  TOGGLE_PENDING_SERVICE,
  TOGGLE_REFRESHING_SERVICE,
  SET_SERVICE,
  SET_ERROR_SERVICE,
};

export default exportedObject;
