import { GET_SERVICE, GET_SERVICE_MENU_ITEM, POST_ORDER } from 'src/constants';

import { http } from 'src/helpers/http';

export const getService = async ({ id }) => {
  const response = await http.get(GET_SERVICE.replace(':id', id));

  return response;
};

export const getItem = async (id) => {
  const response = await http.get(GET_SERVICE_MENU_ITEM.replace(':id', id));

  return response;
};

export const submitOrder = async ({ data }) => {
  const response = await http.post(POST_ORDER, data);

  return response;
};

const exportedObject = {
  getService,
  getItem,
  submitOrder,
};

export default exportedObject;
