import guideActions from './actions';

import guideAPI from '../../api/guide';

const getCategories = () => async (dispatch, getState) => {
  const { guide } = getState();
  const { progress } = guide;

  if (progress.pendingCategories) return;

  dispatch(guideActions.togglePendingCategories(true));
  dispatch(
    guideActions.setErrorCategories({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await guideAPI.getCategories();

    dispatch(guideActions.setCategories(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        guideActions.setErrorCategories({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        guideActions.setErrorCategories({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(guideActions.togglePendingCategories(false));
  }
};

const getItems = (categoryId) => async (dispatch, getState) => {
  const { guide, profile } = getState();
  const { progress } = guide;
  const cityId = profile.data.stayInfo?.hotel?.city?.id;

  if (progress.pendingItems) return;

  dispatch(guideActions.togglePendingItems(true));
  dispatch(
    guideActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await guideAPI.getItems({
      category: categoryId,
      city: cityId,
      page: 1,
    });

    dispatch(guideActions.setPageItems(1));
    dispatch(guideActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        guideActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        guideActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(guideActions.togglePendingItems(false));
  }
};

const refreshItems = (categoryId) => async (dispatch, getState) => {
  const { guide, profile } = getState();
  const { progress } = guide;
  const cityId = profile.data.stayInfo?.hotel?.city?.id;

  if (progress.refreshingItems) return;

  dispatch(guideActions.toggleRefreshingItems(true));
  dispatch(
    guideActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await guideAPI.getItems({
      category: categoryId,
      city: cityId,
      page: 1,
    });

    dispatch(guideActions.setPageItems(1));
    dispatch(guideActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        guideActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        guideActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(guideActions.toggleRefreshingItems(false));
  }
};

const getMoreItems = (categoryId) => async (dispatch, getState) => {
  const { guide, profile } = getState();
  const { page, progress } = guide;
  const cityId = profile.data.stayInfo?.hotel?.city?.id;

  if (progress.moreItems) return;

  dispatch(guideActions.toggleMoreItems(true));
  dispatch(
    guideActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await guideAPI.getItems({
      category: categoryId,
      city: cityId,
      page: page.items + 1,
    });

    dispatch(guideActions.incrementPageItems());
    dispatch(guideActions.setMoreItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        guideActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        guideActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(guideActions.toggleMoreItems(false));
  }
};

const setActiveCategory = (id) => async (dispatch) => {
  dispatch(guideActions.setActiveCategory(id));
};

const getItem = (id) => async (dispatch) => {
  dispatch(guideActions.togglePendingItem(true));
  dispatch(
    guideActions.setErrorItem({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await guideAPI.getItem(id);

    dispatch(guideActions.setItem(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        guideActions.setErrorItem({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        guideActions.setErrorItem({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(guideActions.togglePendingItem(false));
  }
};

const exportedObject = {
  getCategories,
  getItems,
  refreshItems,
  getMoreItems,
  setActiveCategory,
  getItem,
};

export default exportedObject;
