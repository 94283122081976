// ** React
import { useState, useEffect, useRef } from 'react';

// ** Worker Timers
import { setInterval } from 'worker-timers';

// ** Reconnecting Websocket
import ReconnectingWebSocket from 'reconnecting-websocket';

// ** Constants
import { WS_BASE_URL, USE_LOCAL_STORAGE } from 'src/constants';

// ** Helpers
import eventBus from 'src/helpers/eventBus';
import { getData } from 'src/helpers/storage';

// ** Redux
import { connect } from 'react-redux';
import { notificationsOperations } from 'src/store/notifications';

const urlProvider = async (access, token) => {
  let url = null;
  if (access) {
    url = `${WS_BASE_URL}client/?bearer=${access}`;
  } else {
    url = `${WS_BASE_URL}client/?device_token=${token}`;
  }
  return url;
};

const WebSocketClient = ({ profile, setNotifications, clearNotifications, children }) => {
  const interval = useRef(false);

  const access = profile.loginInfo?.access;
  const token = profile.uniqueId?.token;

  const stayId = profile.stayInfo?.id;

  const [websocket, setWebsocket] = useState(null);

  const _handleWebSocketInit = async () => {
    websocket && websocket.close();

    const url = await urlProvider(access, token);
    const connection = new ReconnectingWebSocket(url);
    setWebsocket(connection);
  };

  const send = ({ content, id }) => {
    websocket &&
      websocket.send(
        JSON.stringify({
          command: 'send',
          message: content,
          service_order_id: id,
          source: 300,
        }),
      );
  };

  const fetch = async () => {
    let current = {};
    let syncDate = null;
    if (USE_LOCAL_STORAGE) {
      current = getData('notifications');
      syncDate = current?.[stayId]?.sync;
    }

    websocket &&
      websocket.send(
        JSON.stringify({
          command: 'get_messages',
          stay_id: stayId,
          ...(syncDate ? { from_time: syncDate } : {}),
        }),
      );
  };

  const message = (event) => {
    const eventData = JSON.parse(event.data);
    const eventType = eventData.type;

    switch (eventType) {
      case 'chat':
        setNotifications(eventData.payload);

        eventBus.publish('ws:message');

        break;
      default:
        break;
    }
  };

  const disconnect = () => {
    websocket && websocket.close();
  };

  useEffect(() => {
    if (!websocket) {
      return;
    }

    const onOpen = () => {
      console.info('WS: Connected');

      interval.current = setInterval(() => {
        websocket.send(
          JSON.stringify({
            command: 'ping',
          }),
        );
      }, 1800000); // 30m

      fetch();
    };

    const onClose = () => {
      console.info('WS: Disconnected');
    };

    const onError = (e) => {
      console.error('WS: Error:', e);
    };

    websocket.addEventListener('open', onOpen);
    websocket.addEventListener('close', onClose);
    websocket.addEventListener('error', onError);
    websocket.addEventListener('message', (event) => message(event));

    eventBus.subscribe('ws:send', ({ content, id }) => send({ content, id }));
    eventBus.subscribe('ws:disconnect', () => disconnect());

    return () => {
      websocket.removeEventListener('open', onOpen);
      websocket.removeEventListener('close', onClose);
      websocket.removeEventListener('error', onError);
      websocket.removeEventListener('message', message);
      websocket && websocket.close();
    };
  }, [websocket]);

  useEffect(() => {
    if (!stayId) {
      disconnect();

      return;
    }

    clearNotifications();
    _handleWebSocketInit();

    return () => {};
  }, [stayId, access, token]);

  return children;
};

const mapStateToProps = (state) => ({
  profile: state.profile.data,
});

const mapDispatchToProps = {
  setNotifications: notificationsOperations.setNotifications,
  clearNotifications: notificationsOperations.clearNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketClient);
