import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  info: {},
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_SERVICE:
      return {
        ...state,
        info: payload,
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pending: false,
  refreshing: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_SERVICE:
      return {
        ...state,
        pending: payload,
      };
    case types.TOGGLE_REFRESHING_SERVICE:
      return {
        ...state,
        refreshing: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorMain: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_SERVICE:
      return {
        ...state,
        errorMain: payload,
      };
    default:
      return state;
  }
};
export default combineReducers({
  data,
  progress,
  error,
});
