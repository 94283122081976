const CrossIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox={'0 0 24 24'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.383 4.383c.51-.51 1.337-.51 1.847 0l5.77 5.77 5.77-5.77a1.306 1.306 0 0 1 1.741-.096l.106.096c.51.51.51 1.337 0 1.847L13.847 12l5.77 5.77c.477.476.508 1.229.096 1.741l-.096.106c-.51.51-1.337.51-1.847 0L12 13.847l-5.77 5.77a1.306 1.306 0 0 1-1.741.096l-.106-.096a1.306 1.306 0 0 1 0-1.847l5.77-5.77-5.77-5.77a1.306 1.306 0 0 1-.096-1.741Z"
        fill="#1A1A1C"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CrossIcon;
