const SET_CATEGORIES = 'guide/SET_CATEGORIES';
const TOGGLE_PENDING_CATEGORIES = 'guide/TOGGLE_PENDING_CATEGORIES';
const SET_ERROR_CATEGORIES = 'guide/SET_ERROR_CATEGORIES';
const SET_ITEMS = 'guide/SET_ITEMS';
const TOGGLE_PENDING_ITEMS = 'guide/TOGGLE_PENDING_ITEMS';
const SET_ERROR_ITEMS = 'guide/SET_ERROR_ITEMS';
const TOGGLE_REFRESHING_ITEMS = 'guide/TOGGLE_REFRESHING_ITEMS';
const TOGGLE_MORE_ITEMS = 'guide/TOGGLE_MORE_ITEMS';
const SET_MORE_ITEMS = 'guide/SET_MORE_ITEMS';
const SET_ACTIVE_CATEGORY = 'guide/SET_ACTIVE_CATEGORY';
const SET_ITEM = 'guide/SET_ITEM';
const TOGGLE_PENDING_ITEM = 'guide/TOGGLE_PENDING_ITEM';
const SET_ERROR_ITEM = 'guide/SET_ERROR_ITEM';
const SET_PAGE_ITEMS = 'guide/SET_PAGE_ITEMS';
const INCREMENT_PAGE_ITEMS = 'guide/INCREMENT_PAGE_ITEMS';
const DECREMENT_PAGE_ITEMS = 'guide/DECREMENT_PAGE_ITEMS';

const exportedObject = {
  SET_CATEGORIES,
  TOGGLE_PENDING_CATEGORIES,
  SET_ERROR_CATEGORIES,
  SET_ITEMS,
  TOGGLE_PENDING_ITEMS,
  SET_ERROR_ITEMS,
  TOGGLE_REFRESHING_ITEMS,
  TOGGLE_MORE_ITEMS,
  SET_MORE_ITEMS,
  SET_ACTIVE_CATEGORY,
  SET_ITEM,
  TOGGLE_PENDING_ITEM,
  SET_ERROR_ITEM,
  SET_PAGE_ITEMS,
  INCREMENT_PAGE_ITEMS,
  DECREMENT_PAGE_ITEMS,
};

export default exportedObject;
