import {
  GET_MENUS,
  POST_TOKEN,
  POST_STAY,
  GET_CONFIG,
  POST_LOGIN,
  POST_LOGIN_BY_OAUTH,
  GET_LOGIN_BY_OAUTH,
  POST_REGISTER,
  POST_REFRESH,
  GET_USERS_ME,
  PATCH_USERS_ME,
  POST_SET_PASSWORD,
  POST_RESET_PASSWORD,
  POST_SET_EMAIL,
  POST_FCM_DEVICE_CREATE,
  POST_ACTIVATION,
  POST_RESET_PASSWORD_CONFIRM,
} from 'src/constants';

import { http, httpAuth } from 'src/helpers/http';

export const getMenus = async ({ hotel, page = 1 }) => {
  const response = await http.get(GET_MENUS, {
    params: {
      page_size: 20,
      page: page,
      hotel: hotel,
    },
  });

  return response;
};

export const getToken = async ({ id }) => {
  const response = await http.post(POST_TOKEN, {
    uuid: id,
  });

  return response;
};

export const getConfig = async () => {
  const response = await http.get(GET_CONFIG);

  return response;
};

export const submitStay = async ({ hotelCode, hotelId, guestName, roomNumber, start, end }) => {
  const response = await http.post(POST_STAY, {
    hotelCode: hotelCode,
    hotelId: hotelId,
    guestName: guestName,
    roomNumber: roomNumber,
    start: start,
    end: end,
  });

  return response;
};

export const login = async ({ email, password }) => {
  const response = await httpAuth.post(POST_LOGIN, {
    email: email,
    password: password,
  });

  return response;
};

export const loginBySocial = async (provider, { code, redirectUri = 'postmessage', deviceId }) => {
  const response = await httpAuth.post(POST_LOGIN_BY_OAUTH, {
    provider: provider,
    code: code,
    redirect_uri: redirectUri,
    device_id: deviceId,
  });

  return response;
};

export const getLoginBySocial = async (provider) => {
  const response = await httpAuth.get(GET_LOGIN_BY_OAUTH, {
    params: {
      provider: provider,
    },
  });

  return response;
};

export const register = async ({ email, password, phoneNumber, firstName, lastName }) => {
  const response = await httpAuth.post(POST_REGISTER, {
    email: email,
    password: password,
    firstName: firstName,
    lastName: lastName,
    ...(phoneNumber
      ? {
          phoneNumber: phoneNumber,
        }
      : {}),
  });

  return response;
};

export const refresh = async (token) => {
  const response = await httpAuth.post(POST_REFRESH, {
    refresh: token,
  });

  return response;
};

export const setPassword = async ({ newPassword, currentPassword }) => {
  const response = await http.post(POST_SET_PASSWORD, {
    newPassword: newPassword,
    currentPassword: currentPassword,
  });

  return response;
};

export const resetPassword = async ({ email }) => {
  const response = await http.post(POST_RESET_PASSWORD, {
    email: email,
  });

  return response;
};

export const setEmail = async ({ currentPassword, newEmail }) => {
  const response = await http.post(POST_SET_EMAIL, {
    newEmail: newEmail,
    currentPassword: currentPassword,
  });

  return response;
};

export const getProfile = async () => {
  const response = await http.get(GET_USERS_ME);

  return response;
};

export const editProfile = async (values) => {
  const response = await http.patch(PATCH_USERS_ME, values);

  return response;
};

export const registerFcmDevice = async (values) => {
  const response = await http.post(POST_FCM_DEVICE_CREATE, values);

  return response;
};

const activate = async ({ uid, token }) => {
  const response = await httpAuth.post(POST_ACTIVATION, {
    uid: uid,
    token: token,
  });

  return response;
};

const resetPasswordConfirm = async ({ uid, token, newPassword }) => {
  const response = await httpAuth.post(POST_RESET_PASSWORD_CONFIRM, {
    uid: uid,
    token: token,
    newPassword: newPassword,
  });

  return response;
};

const exportedObject = {
  getMenus,
  getToken,
  getConfig,
  submitStay,
  login,
  loginBySocial,
  getLoginBySocial,
  register,
  setPassword,
  resetPassword,
  setEmail,
  refresh,
  getProfile,
  editProfile,
  registerFcmDevice,
  activate,
  resetPasswordConfirm,
};

export default exportedObject;
