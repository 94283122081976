import hotelActions from './actions';
import hotelAPI from '../../api/hotel';
import isEmpty from 'lodash/isEmpty';

const getItems = () => async (dispatch, getState) => {
  const { hotel, profile } = getState();
  const { progress } = hotel;
  const { menus } = profile.data;

  if (progress.pendingItems) return;

  let menuId;
  if (menus.list) {
    let menuObj = menus.list.find((a) => a.type === 'hotel');
    menuId = menuObj && menuObj.id;
  }

  if (!menuId) {
    dispatch(hotelActions.setPageItems(1));
    dispatch(hotelActions.resetItems());
    return;
  }

  dispatch(hotelActions.togglePendingItems(true));
  dispatch(
    hotelActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await hotelAPI.getItems({
      page: 1,
      menu: menuId,
    });

    dispatch(hotelActions.setPageItems(1));
    dispatch(hotelActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        hotelActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        hotelActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(hotelActions.togglePendingItems(false));
  }
};

const refreshItems = () => async (dispatch, getState) => {
  const { hotel, profile } = getState();
  const { progress } = hotel;
  const { menus } = profile.data;

  if (progress.refreshingItems) return;

  let menuId;
  if (menus.list) {
    let menuObj = menus.list.find((a) => a.type === 'hotel');
    menuId = menuObj && menuObj.id;
  }

  if (!menuId) {
    dispatch(hotelActions.setPageItems(1));
    dispatch(hotelActions.resetItems());
    return;
  }

  dispatch(hotelActions.toggleRefreshingItems(true));
  dispatch(
    hotelActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await hotelAPI.getItems({
      page: 1,
      menu: menuId,
    });

    dispatch(hotelActions.setPageItems(1));
    dispatch(hotelActions.setItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        hotelActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        hotelActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(hotelActions.toggleRefreshingItems(false));
  }
};

const getMoreItems = () => async (dispatch, getState) => {
  const { hotel, profile } = getState();
  const { page, progress } = hotel;
  const { menus } = profile.data;

  if (progress.moreItems) return;

  let menuId;
  if (menus.list) {
    let menuObj = menus.list.find((a) => a.type === 'hotel');
    menuId = menuObj && menuObj.id;
  }

  if (!menuId) {
    dispatch(hotelActions.setPageItems(1));
    dispatch(hotelActions.resetItems());
    return;
  }

  dispatch(hotelActions.toggleMoreItems(true));
  dispatch(
    hotelActions.setErrorItems({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await hotelAPI.getItems({
      page: page + 1,
      menu: menuId,
    });

    dispatch(hotelActions.incrementPageItems());
    dispatch(hotelActions.setMoreItems(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        hotelActions.setErrorItems({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        hotelActions.setErrorItems({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(hotelActions.toggleMoreItems(false));
  }
};

const getImages = () => async (dispatch, getState) => {
  const { hotel, profile } = getState();
  const { progress } = hotel;
  const hotelInfo = profile.data.stayInfo?.hotel;

  const defaultImageObj = profile.data.config.gallery?.defaultImages?.find((a) => a.type === 'hotel');

  if (progress.pendingImages) return;

  dispatch(hotelActions.togglePendingImages(true));
  dispatch(
    hotelActions.setErrorImages({
      status: null,
      text: null,
    }),
  );

  try {
    const response = await hotelAPI.getHotelImages(hotelInfo.id);
    let results = response.data?.results;
    if (isEmpty(results) && defaultImageObj) {
      results.push(defaultImageObj);
    }
    dispatch(hotelActions.setImages(results));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        hotelActions.setErrorImages({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        hotelActions.setErrorImages({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(hotelActions.togglePendingImages(false));
  }
};

const exportedObject = {
  getItems,
  refreshItems,
  getMoreItems,
  getImages,
};

export default exportedObject;
