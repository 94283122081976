import { GET_LANDINGS } from 'src/constants';

import { http } from 'src/helpers/http';

const getLandings = async () => {
  const response = await http.get(GET_LANDINGS, {
    params: {
      page_size: 999,
      is_main: true,
    },
  });

  return response;
};

const exportedObject = {
  getLandings,
};

export default exportedObject;
