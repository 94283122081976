import serviceActions from './actions';

import serviceAPI from '../../api/service';

const getService = (id) => async (dispatch) => {
  dispatch(serviceActions.togglePendingService(true));
  dispatch(
    serviceActions.setErrorService({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await serviceAPI.getService({
      id: id,
    });
    dispatch(serviceActions.setService(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        serviceActions.setErrorService({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        serviceActions.setErrorService({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(serviceActions.togglePendingService(false));
  }
};

const refreshService = (id) => async (dispatch) => {
  dispatch(serviceActions.toggleRefreshingService(true));
  dispatch(
    serviceActions.setErrorService({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await serviceAPI.getService({
      id: id,
    });
    dispatch(serviceActions.setService(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        serviceActions.setErrorService({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        serviceActions.setErrorService({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(serviceActions.toggleRefreshingService(false));
  }
};

const submitOrder = (formData) => async () => {
  const response = await serviceAPI.submitOrder({
    data: formData,
  });

  return response;
};

const exportedObject = {
  getService,
  refreshService,
  submitOrder,
};

export default exportedObject;
