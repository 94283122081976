import types from './types';

const togglePendingLanding = (payload, meta) => ({
  type: types.TOGGLE_PENDING_LANDING,
  payload,
  meta,
});

const setLanding = (payload, meta) => ({
  type: types.SET_LANDING,
  payload,
  meta,
});

const setErrorLanding = (payload, meta) => ({
  type: types.SET_ERROR_LANDING,
  payload,
  meta,
});

const exportedObject = {
  togglePendingLanding,
  setLanding,
  setErrorLanding,
};

export default exportedObject;
