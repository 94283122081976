import types from './types';

const togglePendingItems = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ITEMS,
  payload,
  meta,
});

const toggleRefreshingItems = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_ITEMS,
  payload,
  meta,
});

const toggleMoreItems = (payload, meta) => ({
  type: types.TOGGLE_MORE_ITEMS,
  payload,
  meta,
});

const resetItems = (meta) => ({
  type: types.RESET_ITEMS,
  meta,
});

const setItems = (payload, meta) => ({
  type: types.SET_ITEMS,
  payload,
  meta,
});

const setMoreItems = (payload, meta) => ({
  type: types.SET_MORE_ITEMS,
  payload,
  meta,
});

const setErrorItems = (payload, meta) => ({
  type: types.SET_ERROR_ITEMS,
  payload,
  meta,
});

const setPageItems = (payload, meta) => ({
  type: types.SET_PAGE_ITEMS,
  payload,
  meta,
});

const incrementPageItems = (meta) => ({
  type: types.INCREMENT_PAGE_ITEMS,
  meta,
});

const decrementPageItems = (meta) => ({
  type: types.DECREMENT_PAGE_ITEMS,
  meta,
});

const setImages = (payload, meta) => ({
  type: types.SET_IMAGES,
  payload,
  meta,
});

const togglePendingImages = (payload, meta) => ({
  type: types.TOGGLE_PENDING_IMAGES,
  payload,
  meta,
});

const setErrorImages = (payload, meta) => ({
  type: types.SET_ERROR_IMAGES,
  payload,
  meta,
});

const exportedObject = {
  togglePendingItems,
  toggleRefreshingItems,
  toggleMoreItems,
  resetItems,
  setItems,
  setMoreItems,
  setErrorItems,
  setPageItems,
  incrementPageItems,
  decrementPageItems,
  setImages,
  togglePendingImages,
  setErrorImages,
};

export default exportedObject;
