// ** React
import { forwardRef } from 'react';

// ** MUI
import { Box, Dialog, Fade } from '@mui/material';

// ** Redux
import { connect } from 'react-redux';

// ** Lottie
import Lottie from 'react-lottie-player';

// ** Assets
import SplashHotelsJson from 'src/assets/lottie/splash-hotels.json';
import SplashBuildingsJson from 'src/assets/lottie/splash-buildings.json';
import SplashCarsJson from 'src/assets/lottie/splash-cars.json';
import SplashOporaJson from 'src/assets/lottie/splash-opora.json';

const splashJsonSrc = (project) => {
  switch (project) {
    case 'buildings':
      return SplashBuildingsJson;
    case 'cars':
      return SplashCarsJson;
    case 'opora':
      return SplashOporaJson;
    default:
      return SplashHotelsJson;
  }
};

const Transition = forwardRef((props, ref) => {
  return <Fade in={true} ref={ref} {...props} />;
});

const SplashModal = ({ project, loader, setHasAnimationPlayedOnce }) => {
  const handleAnimationFinish = () => {
    setHasAnimationPlayedOnce(true);
  };

  return (
    <Dialog
      open={loader}
      fullScreen
      sx={{
        zIndex: '1500 !important',
        '& .MuiDialog-paper': {
          backgroundColor: '#FFDF00',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        },
      }}
      disableEscapeKeyDown
      TransitionComponent={Transition}
    >
      <Box maxWidth={256} maxHeight={256}>
        <Lottie
          loop
          animationData={splashJsonSrc(project)}
          speed={1.5}
          onLoopComplete={() => handleAnimationFinish()}
          play
          style={{ width: 256, height: 256 }}
        />
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  loader: state.profile.data.loader,
});

export default connect(mapStateToProps, null)(SplashModal);
