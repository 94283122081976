import helpersActions from './actions';

import { getData, setData } from '../../helpers/storage';

import helpersAPI from '../../api/helpers';

import isArray from 'lodash/isArray';

const getOrderStatuses = (lang) => async (dispatch) => {
  dispatch(helpersActions.togglePendingOrderStatuses(true));

  try {
    let info = getData(`orderStatuses_${lang}`);
    const fetchedTime = getData(`orderStatuses_${lang}_fetched`) || 0;

    if (!info || (Date.now() - fetchedTime || !isArray(info)) > 604800) {
      const { data } = await helpersAPI.getOrderStatuses();
      info = data;

      setData(`orderStatuses_${lang}`, info);
      setData(`orderStatuses_${lang}_fetched`, Date.now());
    }
    dispatch(helpersActions.setOrderStatuses(info));
  } catch ({ response }) {
    //
  } finally {
    dispatch(helpersActions.togglePendingOrderStatuses(false));
  }
};

const getGeoLevels = () => async (dispatch) => {
  dispatch(helpersActions.togglePendingGeoLevels(true));

  try {
    let info = getData('geoLevels');
    const fetchedTime = getData('geoLevels_fetched') || 0;

    if (!info || Date.now() - fetchedTime > 604800 || !isArray(info)) {
      const { data } = await helpersAPI.getGeoLevels();
      info = data;

      setData('geoLevels', info);
      setData('geoLevels_fetched', Date.now());
    }
    dispatch(helpersActions.setGeoLevels(info));
  } catch ({ response }) {
    //
  } finally {
    dispatch(helpersActions.togglePendingGeoLevels(false));
  }
};

const getMessagesTypes = (lang) => async (dispatch) => {
  dispatch(helpersActions.togglePendingMessagesTypes(true));

  try {
    let info = getData(`messagesTypes_${lang}`);
    const fetchedTime = getData(`messagesTypes_${lang}`) || 0;

    if (!info || Date.now() - fetchedTime > 604800 || !isArray(info)) {
      const { data } = await helpersAPI.getMessagesTypes();
      info = data;

      setData(`messagesTypes_${lang}`, info);
      setData(`messagesTypes_${lang}_fetched`, Date.now());
    }
    dispatch(helpersActions.setMessagesTypes(info));
  } catch ({ response }) {
    //
  } finally {
    dispatch(helpersActions.togglePendingMessagesTypes(false));
  }
};

const getLanguages = (lang) => async (dispatch) => {
  dispatch(helpersActions.togglePendingLanguages(true));

  try {
    let info = getData(`languages_${lang}`);
    const fetchedTime = getData(`languages_${lang}`) || 0;

    if (!info || Date.now() - fetchedTime > 604800 || !isArray(info)) {
      const { data } = await helpersAPI.getLanguages();
      info = data;

      setData(`languages_${lang}`, info);
      setData(`languages_${lang}_fetched`, Date.now());
    }
    dispatch(helpersActions.setLanguages(info));
  } catch ({ response }) {
    //
  } finally {
    dispatch(helpersActions.togglePendingLanguages(false));
  }
};

const checkUpdate =
  ({ version, platform }) =>
  async (dispatch) => {
    dispatch(helpersActions.togglePendingUpdate(true));
    dispatch(
      helpersActions.setErrorUpdate({
        status: null,
        text: null,
      }),
    );

    try {
      const { data } = await helpersAPI.checkUpdate({
        version: version,
        platform: platform,
      });
      dispatch(helpersActions.setUpdate(data));
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          helpersActions.setErrorUpdate({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          helpersActions.setErrorUpdate({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
    } finally {
      dispatch(helpersActions.togglePendingUpdate(false));
    }
  };

const getSystem = () => async (dispatch) => {
  dispatch(helpersActions.togglePendingSystem(true));
  dispatch(
    helpersActions.setErrorSystem({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await helpersAPI.getSystem();
    dispatch(helpersActions.setSystem(data.results));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        helpersActions.setErrorSystem({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        helpersActions.setErrorSystem({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(helpersActions.togglePendingSystem(false));
  }
};

const exportedObject = {
  getOrderStatuses,
  getGeoLevels,
  getMessagesTypes,
  getLanguages,
  checkUpdate,
  getSystem,
};

export default exportedObject;
