const RESET_ITEMS = 'hotel/RESET_ITEMS';
const SET_ITEMS = 'hotel/SET_ITEMS';
const SET_MORE_ITEMS = 'hotel/SET_MORE_ITEMS';
const TOGGLE_PENDING_ITEMS = 'hotel/TOGGLE_PENDING_ITEMS';
const TOGGLE_REFRESHING_ITEMS = 'hotel/TOGGLE_REFRESHING_ITEMS';
const TOGGLE_MORE_ITEMS = 'hotel/TOGGLE_MORE_ITEMS';
const SET_ERROR_ITEMS = 'hotel/SET_ERROR_ITEMS';
const SET_PAGE_ITEMS = 'hotel/SET_PAGE_ITEMS';
const INCREMENT_PAGE_ITEMS = 'hotel/INCREMENT_PAGE_ITEMS';
const DECREMENT_PAGE_ITEMS = 'hotel/DECREMENT_PAGE_ITEMS';

const SET_IMAGES = 'hotel/SET_IMAGES';
const TOGGLE_PENDING_IMAGES = 'hotel/TOGGLE_PENDING_IMAGES';
const SET_ERROR_IMAGES = 'hotel/SET_ERROR_IMAGES';

const exportedObject = {
  RESET_ITEMS,
  SET_ITEMS,
  SET_MORE_ITEMS,
  TOGGLE_PENDING_ITEMS,
  TOGGLE_REFRESHING_ITEMS,
  TOGGLE_MORE_ITEMS,
  SET_ERROR_ITEMS,
  SET_PAGE_ITEMS,
  INCREMENT_PAGE_ITEMS,
  DECREMENT_PAGE_ITEMS,
  SET_IMAGES,
  TOGGLE_PENDING_IMAGES,
  SET_ERROR_IMAGES,
};

export default exportedObject;
