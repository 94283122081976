// ** React DOM
import { createRoot } from 'react-dom/client';

// ** App
import App from 'src/App';

// ** Service Worker
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';

// ** Web Vitals
import reportWebVitals from 'src/reportWebVitals';

// ** Styles
import 'react-datepicker/dist/react-datepicker.css';
import 'src/styles/index.css';

// ** Constants
import { IS_PRODUCTION } from 'src/constants';

// ** Sentry
import * as Sentry from '@sentry/react';

// ** Language
import 'src/lang/i18n';

// ** Icons
import 'material-symbols/outlined.css';

Sentry.init({
  dsn: 'https://a174a42b69004bbd80cc61c1b0c4c286@sentry2.m5test.ru/11',
  integrations: [Sentry.browserTracingIntegration()],
  environment: IS_PRODUCTION === true ? 'prod' : 'dev',
  tracesSampleRate: 1.0,
});

// let dynamicManifest = {
//   short_name: 'Your Hotel App',
//   name: 'Your Hotel App',
//   icons: [
//     {
//       src: window.location.origin + '/favicon.ico',
//       sizes: '64x64 32x32 24x24 16x16',
//       type: 'image/x-icon',
//     },
//     {
//       src: window.location.origin + '/logo192.png',
//       type: 'image/png',
//       sizes: '192x192',
//     },
//     {
//       src: window.location.origin + '/logo512.png',
//       type: 'image/png',
//       sizes: '512x512',
//     },
//   ],
//   start_url: window.location.origin,
//   display: 'standalone',
//   orientation: 'landscape',
//   theme_color: '#000000',
//   background_color: '#ffffff',
// };

// const link = document.createElement('link');
// link.rel = 'manifest';
// const stringManifest = JSON.stringify(dynamicManifest);
// link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest));
// document.head.appendChild(link);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
