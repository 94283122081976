import { GET_VCATEGORY, GET_VCATEGORY_ITEMS } from 'src/constants';

import { http } from 'src/helpers/http';

export const getVcategory = async ({ id }) => {
  const response = await http.get(GET_VCATEGORY.replace(':id', id));

  return response;
};

export const getVcategoryServices = async ({ vcategory, page = 1 }) => {
  const response = await http.get(GET_VCATEGORY_ITEMS, {
    params: {
      page: page,
      virtual_category: vcategory,
    },
  });

  return response;
};

const exportedObject = {
  getVcategory,
  getVcategoryServices,
};

export default exportedObject;
