import types from './types';

const setNotifications = (payload, meta) => ({
  type: types.SET_NOTIFICATIONS,
  payload,
  meta,
});

const clearNotifications = (meta) => ({
  type: types.CLEAR_NOTIFICATIONS,
  meta,
});

const setCountNotifications = (payload, meta) => ({
  type: types.SET_COUNT_NOTIFICATIONS,
  payload,
  meta,
});

const setErrorMessage = (payload, meta) => ({
  type: types.SET_ERROR_MESSAGE,
  payload,
  meta,
});

const togglePendingNotificationsGroups = (payload, meta) => ({
  type: types.TOGGLE_PENDING_NOTIFICATIONS_GROUPS,
  payload,
  meta,
});

const setNotificationsGroups = (payload, meta) => ({
  type: types.SET_NOTIFICATIONS_GROUPS,
  payload,
  meta,
});

const setErrorNotificationsGroups = (payload, meta) => ({
  type: types.SET_ERROR_NOTIFICATIONS_GROUPS,
  payload,
  meta,
});

const exportedObject = {
  setNotifications,
  clearNotifications,
  setCountNotifications,
  setErrorMessage,
  togglePendingNotificationsGroups,
  setNotificationsGroups,
  setErrorNotificationsGroups,
};

export default exportedObject;
