import { GET_NEARBY_CATEGORIES, GET_NEARBY_ITEMS, GET_NEARBY_ITEM } from 'src/constants';

import { http } from 'src/helpers/http';

export const getCategories = async () => {
  const response = await http.get(GET_NEARBY_CATEGORIES, {
    params: {
      ordering: '-app_order',
    },
  });

  return response;
};

export const getItems = async ({ category, city, page = 1 }) => {
  const response = await http.get(GET_NEARBY_ITEMS, {
    params: {
      category,
      city,
      page_size: 20,
      page: page,
    },
  });

  return response;
};

export const getItem = async (id) => {
  const response = await http.get(GET_NEARBY_ITEM.replace(':id', id));

  return response;
};

const exportedObject = {
  getCategories,
  getItems,
  getItem,
};

export default exportedObject;
