import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATIONS_EN from './translations/en.json';
import TRANSLATIONS_DE from './translations/en.json';
import TRANSLATIONS_RU from './translations/ru.json';
import TRANSLATIONS_FR from './translations/en.json';
import TRANSLATIONS_IT from './translations/en.json';
import TRANSLATIONS_ES from './translations/en.json';
import TRANSLATIONS_SV from './translations/en.json';
import TRANSLATIONS_ZH_HANS from './translations/en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    load: 'languageOnly',
    supportedLngs: ['en', 'de', 'ru', 'fr', 'it', 'es', 'sv', 'zh-hans'],
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'yha_lang',
      caches: ['localStorage'],
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      ru: {
        translation: TRANSLATIONS_RU,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      it: {
        translation: TRANSLATIONS_IT,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      sv: {
        translation: TRANSLATIONS_SV,
      },
      'zh-hans': {
        translation: TRANSLATIONS_ZH_HANS,
      },
    },
  });

export default i18n;
