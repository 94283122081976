import { Box } from '@mui/material';

const classes = {
  root: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingBottom: 'env(safe-area-inset-bottom)',
  },
};

const ContentView = ({ children, contentPadding = 0 }) => {
  return (
    <Box sx={classes.root} style={{ paddingLeft: contentPadding, paddingRight: contentPadding }}>
      {children}
    </Box>
  );
};

export default ContentView;
