import axios from 'axios';
import { getData } from './storage';
import { API_BASE_URL } from 'src/constants';
import { profileOperations } from 'src/store/profile';

// Language
import i18next from 'i18next';

export const http = axios.create({
  baseURL: API_BASE_URL,
});

export const httpInterceptor = (store) => {
  http.interceptors.request.use(
    async (config) => {
      const { resolvedLanguage: language } = i18next;
      const state = store.getState();

      const stayId = state.profile.data.stayInfo?.id;

      const uniqueId = getData('uniqueId');
      const token = uniqueId?.token || null;

      const loginInfo = getData('loginInfo');
      const access = loginInfo?.access || null;

      if (access && !config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${access}`;
      } else if (token && !config.headers['Authorization']) {
        config.headers['Authorization'] = `DEVICE_TOKEN ${token}`;
      }

      if (stayId) {
        config.headers['stay'] = stayId;
      }

      if (language) {
        config.headers['Accept-Language'] = language;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  http.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      let originalRequest = error.config;

      const loginInfo = await getData('loginInfo');
      const access = loginInfo?.access || null;
      const refresh = loginInfo?.refresh || null;

      if (error?.response?.status === 401 && !originalRequest.__retry) {
        originalRequest.__retry = true;
        try {
          if (access) {
            if (refresh) {
              await store.dispatch(profileOperations.refresh(refresh));
              const newTokens = await store.dispatch(profileOperations.refresh(refresh));
              error.response.config.headers['Authorization'] = 'Bearer ' + newTokens.access;

              return http.request(originalRequest);
            } else {
              await store.dispatch(profileOperations.logout());
            }
          } else {
            await store.dispatch(profileOperations.getUniqueId(true));

            return http.request(originalRequest);
          }
        } catch (e) {
          await store.dispatch(profileOperations.logout());
        }
      }
      return Promise.reject(error);
    },
  );
};

export const http2 = axios.create();

export const httpAuth = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

const exportedObject = {
  http,
  httpInterceptor,
  http2,
  httpAuth,
};

export default exportedObject;
