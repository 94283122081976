const TOGGLE_PENDING_ORDER = 'order/TOGGLE_PENDING_ORDER';
const TOGGLE_REFRESHING_ORDER = 'order/TOGGLE_REFRESHING_ORDER';
const SET_ORDER = 'order/SET_ORDER';
const SET_ERROR_ORDER = 'order/SET_ERROR_ORDER';

const TOGGLE_PENDING_ACTION_ORDER = 'order/TOGGLE_PENDING_ACTION_ORDER';
const SET_ERROR_ACTION_ORDER = 'order/SET_ERROR_ACTION_ORDER';

const TOGGLE_PENDING_ORDER_FEEDBACK = 'order/TOGGLE_PENDING_FEEDBACK';
const SET_ORDER_FEEDBACK = 'order/SET_ORDER_FEEDBACK';
const SET_ERROR_ORDER_FEEDBACK = 'order/SET_ERROR_ORDER_FEEDBACK';

const exportedObject = {
  TOGGLE_PENDING_ORDER,
  TOGGLE_REFRESHING_ORDER,
  SET_ORDER,
  SET_ERROR_ORDER,
  TOGGLE_PENDING_ACTION_ORDER,
  SET_ERROR_ACTION_ORDER,
  TOGGLE_PENDING_ORDER_FEEDBACK,
  SET_ORDER_FEEDBACK,
  SET_ERROR_ORDER_FEEDBACK,
};

export default exportedObject;
