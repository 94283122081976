const TOGGLE_PENDING_LANDING = 'landings/TOGGLE_PENDING_LANDING';
const SET_LANDING = 'landings/SET_LANDING';
const SET_ERROR_LANDING = 'landings/SET_ERROR_LANDING';

const exportedObject = {
  TOGGLE_PENDING_LANDING,
  SET_LANDING,
  SET_ERROR_LANDING,
};

export default exportedObject;
