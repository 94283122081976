import types from './types';

const togglePendingStays = (payload, meta) => ({
  type: types.TOGGLE_PENDING_STAYS,
  payload,
  meta,
});

const toggleRefreshingStays = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_STAYS,
  payload,
  meta,
});

const toggleMoreStays = (payload, meta) => ({
  type: types.TOGGLE_MORE_STAYS,
  payload,
  meta,
});

const setStays = (payload, meta) => ({
  type: types.SET_STAYS,
  payload,
  meta,
});

const setMoreStays = (payload, meta) => ({
  type: types.SET_MORE_STAYS,
  payload,
  meta,
});

const setErrorStays = (payload, meta) => ({
  type: types.SET_ERROR_STAYS,
  payload,
  meta,
});

const setPageStays = (payload, meta) => ({
  type: types.SET_PAGE_STAYS,
  payload,
  meta,
});

const incrementPageStays = (meta) => ({
  type: types.INCREMENT_PAGE_STAYS,
  meta,
});

const decrementPageStays = (meta) => ({
  type: types.DECREMENT_PAGE_STAYS,
  meta,
});

const togglePendingHotel = (payload, meta) => ({
  type: types.TOGGLE_PENDING_HOTEL,
  payload,
  meta,
});

const setErrorHotel = (payload, meta) => ({
  type: types.SET_ERROR_HOTEL,
  payload,
  meta,
});

const exportedObject = {
  togglePendingStays,
  toggleRefreshingStays,
  toggleMoreStays,
  setStays,
  setMoreStays,
  setErrorStays,
  setPageStays,
  incrementPageStays,
  decrementPageStays,
  togglePendingHotel,
  setErrorHotel,
};

export default exportedObject;
