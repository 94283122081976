import { GET_CATEGORY, GET_SERVICES } from 'src/constants';

import { http } from 'src/helpers/http';

export const getCategory = async ({ id }) => {
  const response = await http.get(GET_CATEGORY.replace(':id', id));

  return response;
};

export const getCategoryServices = async ({ category, page = 1 }) => {
  const response = await http.get(GET_SERVICES, {
    params: {
      facility__type: 1,
      page: page,
      facility__category: category,
    },
  });

  return response;
};

const exportedObject = {
  getCategory,
  getCategoryServices,
};

export default exportedObject;
