import categoryActions from './actions';

import categoryAPI from '../../api/category';

const getCategory = (id) => async (dispatch, getState) => {
  const { profile } = getState();
  const { data } = profile;
  const { stayInfo } = data;

  dispatch(categoryActions.togglePendingCategory(true));
  dispatch(
    categoryActions.setErrorCategory({
      status: null,
      text: null,
    }),
  );

  try {
    const [info, services] = await Promise.all([
      categoryAPI
        .getCategory({
          id: id,
        })
        .then((d) => d.data),
      categoryAPI
        .getCategoryServices({
          category: id,
          hotel: stayInfo?.hotel?.id,
        })
        .then((d) => d.data),
    ]);
    dispatch(categoryActions.setCategory({ info, services }));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        categoryActions.setErrorCategory({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        categoryActions.setErrorCategory({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(categoryActions.togglePendingCategory(false));
  }
};

const refreshCategory = (id) => async (dispatch) => {
  dispatch(categoryActions.toggleRefreshingCategory(true));
  dispatch(
    categoryActions.setErrorCategory({
      status: null,
      text: null,
    }),
  );

  try {
    const [info, services] = await Promise.all([
      categoryAPI
        .getCategory({
          id: id,
        })
        .then((d) => d.data),
      categoryAPI
        .getCategoryServices({
          category: id,
        })
        .then((d) => d.data),
    ]);
    dispatch(categoryActions.setCategory({ info, services }));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        categoryActions.setErrorCategory({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        categoryActions.setErrorCategory({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(categoryActions.toggleRefreshingCategory(false));
  }
};

const exportedObject = {
  getCategory,
  refreshCategory,
};

export default exportedObject;
