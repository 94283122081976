// ** React
import { useState, useEffect } from 'react';

// ** MUI
import { Dialog, Box, ButtonBase, Typography } from '@mui/material';

// ** Language
import { useTranslation } from 'react-i18next';

// ** Components
import ContentView from 'src/components/ContentView';
import ScrollView from 'src/components/ScrollView';
import Logo from 'src/components/Logo';

// ** Constants
import { PROJECT } from 'src/constants';

// ** Assets
import ServerErrorIcon from 'src/components/svg/ServerErrorIcon';

const classes = {
  dialog: {
    zIndex: '1400 !important',
    '& .MuiDialog-paper': {
      backgroundColor: 'yellow',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
  main: {
    paddingTop: '16px',
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  mainWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    marginBottom: '26px',
  },
  textWrapper: {
    marginBottom: '16px',
  },
  text: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  retryButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 56,
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
    backgroundColor: 'red',
    borderRadius: '12px',
  },
  retryButtonText: {
    fontSize: 18,
    lineHeight: '23px',
    fontWeight: '600',
    color: 'white',
  },
};

const InitialErrorModal = ({ error, retryFn }) => {
  const { t: translation } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleRetryPress = () => {
    retryFn && retryFn();
  };

  useEffect(() => {
    const value = !!error;
    setVisible(value);
  }, [error]);

  return (
    <Dialog open={visible} fullScreen sx={classes.dialog} disableEscapeKeyDown>
      <ContentView>
        <ScrollView>
          <Box sx={classes.main}>
            <Box sx={classes.logoWrapper}>
              <Logo project={PROJECT} size={92} />
            </Box>
            <Box sx={classes.mainWrapper}>
              <Box sx={classes.iconWrapper}>
                <ServerErrorIcon />
              </Box>
              <Box sx={classes.textWrapper}>
                <Typography sx={classes.text}>{translation('serverError')}</Typography>
              </Box>
            </Box>
            <Box sx={classes.buttonsWrapper}>
              <ButtonBase sx={classes.retryButton} onClick={handleRetryPress}>
                <Typography sx={classes.retryButtonText}>{translation('retry')}</Typography>
              </ButtonBase>
            </Box>
          </Box>
        </ScrollView>
      </ContentView>
    </Dialog>
  );
};

export default InitialErrorModal;
