import types from './types';

const togglePendingOrderStatuses = (payload, meta) => ({
  type: types.TOGGLE_PENDING_ORDER_STATUSES,
  payload,
  meta,
});

const setOrderStatuses = (payload, meta) => ({
  type: types.SET_ORDER_STATUSES,
  payload,
  meta,
});

const togglePendingGeoLevels = (payload, meta) => ({
  type: types.TOGGLE_PENDING_GEO_LEVELS,
  payload,
  meta,
});

const setGeoLevels = (payload, meta) => ({
  type: types.SET_GEO_LEVELS,
  payload,
  meta,
});

const togglePendingMessagesTypes = (payload, meta) => ({
  type: types.TOGGLE_PENDING_MESSAGES_TYPES,
  payload,
  meta,
});

const setMessagesTypes = (payload, meta) => ({
  type: types.SET_MESSAGES_TYPES,
  payload,
  meta,
});

const togglePendingLanguages = (payload, meta) => ({
  type: types.TOGGLE_PENDING_LANGUAGES,
  payload,
  meta,
});

const setLanguages = (payload, meta) => ({
  type: types.SET_LANGUAGES,
  payload,
  meta,
});

const togglePendingUpdate = (payload, meta) => ({
  type: types.TOGGLE_PENDING_UPDATE,
  payload,
  meta,
});

const setUpdate = (payload, meta) => ({
  type: types.SET_UPDATE,
  payload,
  meta,
});

const setErrorUpdate = (payload, meta) => ({
  type: types.SET_ERROR_UPDATE,
  payload,
  meta,
});

const togglePendingSystem = (payload, meta) => ({
  type: types.TOGGLE_PENDING_SYSTEM,
  payload,
  meta,
});

const setSystem = (payload, meta) => ({
  type: types.SET_SYSTEM,
  payload,
  meta,
});

const setErrorSystem = (payload, meta) => ({
  type: types.SET_ERROR_SYSTEM,
  payload,
  meta,
});

const exportedObject = {
  togglePendingOrderStatuses,
  setOrderStatuses,
  togglePendingGeoLevels,
  setGeoLevels,
  togglePendingMessagesTypes,
  setMessagesTypes,
  togglePendingLanguages,
  setLanguages,
  togglePendingUpdate,
  setUpdate,
  setErrorUpdate,
  togglePendingSystem,
  setSystem,
  setErrorSystem,
};

export default exportedObject;
