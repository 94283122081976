import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  services: {
    list: [],
    next: null,
    previous: null,
    count: 0,
  },
  images: [],
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.RESET_ITEMS:
      return defaultDataState;
    case types.SET_ITEMS:
      return {
        ...state,
        services: {
          count: payload.count,
          previous: payload.previous,
          next: payload.next,
          list: payload.results,
        },
      };
    case types.SET_MORE_ITEMS:
      return {
        ...state,
        services: {
          count: payload.count,
          previous: payload.previous,
          next: payload.next,
          list: [...state.list, ...payload.results],
        },
      };
    case types.SET_IMAGES:
      return {
        ...state,
        images: payload,
      };
    default:
      return state;
  }
};

const defaultPageState = 0;

const page = (state = defaultPageState, { type, payload }) => {
  switch (type) {
    case types.SET_PAGE_ITEMS:
      return payload;
    case types.INCREMENT_PAGE_ITEMS:
      return state + 1;
    case types.DECREMENT_PAGE_ITEMS:
      return state - 1;
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingItems: false,
  refreshingItems: false,
  moreItems: false,
  pendingImages: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_ITEMS:
      return {
        ...state,
        pendingItems: payload,
      };
    case types.TOGGLE_REFRESHING_ITEMS:
      return {
        ...state,
        refreshingItems: payload,
      };
    case types.TOGGLE_MORE_ITEMS:
      return {
        ...state,
        moreItems: payload,
      };
    case types.TOGGLE_PENDING_IMAGES:
      return {
        ...state,
        pendingImages: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorMain: {
    status: null,
    text: null,
  },
  errorImages: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_ITEMS:
      return {
        ...state,
        errorMain: payload,
      };
    case types.SET_ERROR_IMAGES:
      return {
        ...state,
        errorImages: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  error,
  page,
});
