import { Box } from '@mui/material';

const classes = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};

const ScrollView = ({ children, contentPadding = 24 }) => {
  return (
    <Box sx={classes.wrapper}>
      <Box sx={classes.content} style={{ paddingLeft: contentPadding, paddingRight: contentPadding }}>
        {children}
      </Box>
    </Box>
  );
};

export default ScrollView;
