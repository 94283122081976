import { GET_STAYS, GET_STAY, GET_HOTELS } from 'src/constants';

import { http } from 'src/helpers/http';

const getStays = async ({ page = 1 }) => {
  const response = await http.get(GET_STAYS, {
    params: {
      page_size: 20,
      page: page,
    },
  });

  return response;
};

const getStay = async (id) => {
  const response = await http.get(GET_STAY.replace(':id', id));

  return response;
};

const getHotels = async ({ code, id }) => {
  const response = await http.get(GET_HOTELS, {
    params: {
      ...(code
        ? {
            code: code,
          }
        : {}),
      ...(id
        ? {
            id: id,
          }
        : {}),
    },
  });

  return response;
};

const exportedObject = {
  getStays,
  getStay,
  getHotels,
};

export default exportedObject;
