import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  orderStatuses: [],
  geoLevels: [],
  messagesTypes: [],
  languages: [],
  update: {},
  system: [],
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_ORDER_STATUSES:
      return {
        ...state,
        orderStatuses: payload,
      };
    case types.SET_GEO_LEVELS:
      return {
        ...state,
        geoLevels: payload,
      };
    case types.SET_MESSAGES_TYPES:
      return {
        ...state,
        messagesTypes: [...payload],
      };
    case types.SET_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };
    case types.SET_UPDATE:
      return {
        ...state,
        update: payload,
      };
    case types.SET_SYSTEM:
      return {
        ...state,
        system: payload,
      };
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingOrderStatuses: false,
  pendingGeoLevels: false,
  pendingMessagesTypes: false,
  pendingLanguages: false,
  pendingUpdate: false,
  pendingSystem: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_ORDER_STATUSES:
      return {
        ...state,
        pendingOrderStatuses: payload,
      };
    case types.TOGGLE_PENDING_GEO_LEVELS:
      return {
        ...state,
        pendingGeoLevels: payload,
      };
    case types.TOGGLE_PENDING_MESSAGES_TYPES:
      return {
        ...state,
        pendingMessagesTypes: payload,
      };
    case types.TOGGLE_PENDING_LANGUAGES:
      return {
        ...state,
        pendingLanguages: payload,
      };
    case types.TOGGLE_PENDING_UPDATE:
      return {
        ...state,
        pendingUpdate: payload,
      };
    case types.TOGGLE_PENDING_SYSTEM:
      return {
        ...state,
        pendingSystem: payload,
      };
    default:
      return state;
  }
};

const defaultFetchingTimeState = {
  fetchingTimeOrderStatuses: null,
  fetchingTimeGeoLevels: null,
  fetchingTimeMessagesTypes: null,
  fetchingTimeLanguages: null,
};

const fetchingTime = (state = defaultFetchingTimeState, { type, payload }) => {
  switch (type) {
    case types.SET_FETCHING_TIME_ORDER_STATUSES:
      return {
        ...state,
        fetchingTimeOrderStatuses: payload,
      };
    case types.SET_FETCHING_TIME_GEO_LEVELS:
      return {
        ...state,
        fetchingTimeGeoLevels: payload,
      };
    case types.SET_FETCHING_TIME_MESSAGES_TYPES:
      return {
        ...state,
        fetchingTimeMessagesTypes: payload,
      };
    case types.SET_FETCHING_TIME_LANGUAGES:
      return {
        ...state,
        fetchingTimeLanguages: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorUpdate: {
    status: null,
    text: null,
  },
  errorSystem: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_UPDATE:
      return {
        ...state,
        errorUpdate: payload,
      };
    case types.SET_ERROR_SYSTEM:
      return {
        ...state,
        errorSystem: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  progress,
  fetchingTime,
  error,
});
