import {
  GET_ORDERS,
  GET_ORDER,
  POST_ORDER_CANCEL,
  POST_ORDER_FEEDBACK_INFO,
  POST_ORDER_FEEDBACK_REQUEST,
} from 'src/constants';

import { http } from 'src/helpers/http';

export const getOrders = async ({ page = 1, hotel, status }) => {
  const response = await http.get(GET_ORDERS, {
    params: {
      page_size: 20,
      page: page,
      ...(hotel
        ? {
            stay__hotel: hotel,
          }
        : {}),
      ...(status
        ? {
            status: status,
          }
        : {}),
    },
  });

  return response;
};

export const getOrder = async ({ id }) => {
  const response = await http.get(GET_ORDER.replace(':id', id));

  return response;
};

export const cancelOrder = async ({ id }) => {
  const response = await http.post(POST_ORDER_CANCEL.replace(':id', id));

  return response;
};

export const getOrderFeedback = async ({ id }) => {
  const response = await http.post(POST_ORDER_FEEDBACK_INFO, {
    contentType: 'order',
    objectId: id,
  });

  return response;
};

export const sendOrderFeedback = async ({ id, rating, comment = '' }) => {
  const response = await http.post(POST_ORDER_FEEDBACK_REQUEST, {
    contentType: 'order',
    objectId: id,
    rating: rating,
    comment: comment,
  });

  return response;
};

const exportedObject = {
  getOrders,
  getOrder,
  cancelOrder,
  getOrderFeedback,
  sendOrderFeedback,
};

export default exportedObject;
