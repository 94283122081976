import notificationsActions from './actions';
import notificationsAPI from '../../api/notifications';

import eventBus from '../../helpers/eventBus';

import { USE_LOCAL_STORAGE } from '../../constants';

import { getData, setData } from '../../helpers/storage';

import unionBy from 'lodash/unionBy';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import filter from 'lodash/filter';

const setNotifications = (data) => async (dispatch, getState) => {
  const { profile, notifications } = getState();
  const stayId = profile.data.stayInfo?.id;

  let current = {};
  if (USE_LOCAL_STORAGE) {
    current = getData('notifications');
  } else {
    current = {
      [stayId]: {
        list: notifications.data.list || [],
      },
    };
  }

  const datetime = new Date();

  const payload = {
    sync: datetime.toISOString(),
    list: unionBy(current?.[stayId]?.list, data, 'id'),
    count: data.length,
  };

  if (USE_LOCAL_STORAGE) {
    const obj = merge(current, { [stayId]: payload });
    setData('notifications', obj);
  }

  dispatch(notificationsActions.setNotifications(payload));

  dispatch(restoreCountNotifications());

  const ids = uniq(map(data, 'service_order.id'));
  ids.forEach((id) => {
    const idResults = filter(data, (obj) => obj.service_order?.id === +id);
    dispatch(setCountNotifications(id, idResults.length));
  });
};

const clearNotifications = () => async (dispatch) => {
  dispatch(notificationsActions.clearNotifications());
};

const submitMessage = (content, id) => async (dispatch) => {
  dispatch(
    notificationsActions.setErrorMessage({
      status: null,
      text: null,
    }),
  );

  if (!content) {
    dispatch(
      notificationsActions.setErrorMessage({
        status: '0',
        text: 'enterYourMessageError',
      }),
    );
    return false;
  }

  eventBus.publish('ws:send', { content, id });
};

const restoreCountNotifications = () => (dispatch, getState) => {
  const { profile } = getState();
  const stayId = profile.data.stayInfo?.id;

  if (USE_LOCAL_STORAGE) {
    const current = getData('notifications_count');
    if (current) dispatch(notificationsActions.setCountNotifications(current[stayId]));
  }
};

const setCountNotifications = (id, count) => (dispatch, getState) => {
  const { profile, notifications } = getState();
  const stayId = profile.data.stayInfo?.id;

  let current = {};
  if (USE_LOCAL_STORAGE) {
    current = getData('notifications_count');
  } else {
    current = {
      [stayId]: notifications.count || {},
    };
  }

  const payload = {
    ...current?.[stayId],
    [id]: count,
  };

  if (USE_LOCAL_STORAGE) {
    const obj = merge(current, { [stayId]: payload });
    setData('notifications_count', obj);
  }

  dispatch(notificationsActions.setCountNotifications(payload));
};

const resetCountNotifications = (id) => (dispatch) => {
  dispatch(setCountNotifications(id, 0));
};

const translateMessage =
  ({ id, language }) =>
  async () => {
    try {
      const response = await notificationsAPI.translateMessage({
        id: id,
        language: language,
      });
      return response;
    } catch ({ response }) {
      return response;
    }
  };

const getNotificationsGroups = () => async (dispatch) => {
  dispatch(notificationsActions.togglePendingNotificationsGroups(true));
  dispatch(
    notificationsActions.setErrorNotificationsGroups({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await notificationsAPI.getNotificationsGroups();
    dispatch(notificationsActions.setNotificationsGroups(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        notificationsActions.setErrorNotificationsGroups({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        notificationsActions.setErrorNotificationsGroups({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(notificationsActions.togglePendingNotificationsGroups(false));
  }
};

const exportedObject = {
  setNotifications,
  clearNotifications,
  submitMessage,
  setCountNotifications,
  resetCountNotifications,
  translateMessage,
  getNotificationsGroups,
};

export default exportedObject;
