import ordersActions from './actions';

import ordersAPI from '../../api/orders';

const getOrders =
  ({ status }) =>
  async (dispatch, getState) => {
    const {
      orders: { progress: ordersProgress },
      profile: { data: profileData },
    } = getState();
    const hotelId = profileData.stayInfo?.hotel?.id;

    if (ordersProgress.pendingOrders) return;

    dispatch(ordersActions.togglePendingOrders(true));
    dispatch(
      ordersActions.setErrorOrders({
        status: null,
        text: null,
      }),
    );

    try {
      const { data } = await ordersAPI.getOrders({
        hotel: hotelId,
        status: status,
        page: 1,
      });

      dispatch(ordersActions.setPageOrders(1));
      dispatch(ordersActions.setOrders(data));
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          ordersActions.setErrorOrders({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          ordersActions.setErrorOrders({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
    } finally {
      dispatch(ordersActions.togglePendingOrders(false));
    }
  };

const refreshOrders =
  ({ status }) =>
  async (dispatch, getState) => {
    const {
      orders: { progress: ordersProgress },
      profile: { data: profileData },
    } = getState();
    const hotelId = profileData.stayInfo?.hotel?.id;

    if (ordersProgress.refreshingOrders) return;

    dispatch(ordersActions.toggleRefreshingOrders(true));
    dispatch(
      ordersActions.setErrorOrders({
        status: null,
        text: null,
      }),
    );

    try {
      const { data } = await ordersAPI.getOrders({
        hotel: hotelId,
        status: status,
        page: 1,
      });

      dispatch(ordersActions.setPageOrders(1));
      dispatch(ordersActions.setOrders(data));
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          ordersActions.setErrorOrders({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          ordersActions.setErrorOrders({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
    } finally {
      dispatch(ordersActions.toggleRefreshingOrders(false));
    }
  };

const getMoreOrders =
  ({ status }) =>
  async (dispatch, getState) => {
    const {
      orders: { progress: ordersProgress, page: ordersPage },
      profile: { data: profileData },
    } = getState();
    const hotelId = profileData.stayInfo?.hotel?.id;

    if (ordersProgress.moreOrders) return;

    dispatch(ordersActions.toggleMoreOrders(true));
    dispatch(
      ordersActions.setErrorOrders({
        status: null,
        text: null,
      }),
    );

    try {
      const { data } = await ordersAPI.getOrders({
        hotel: hotelId,
        status: status,
        page: ordersPage + 1,
      });

      dispatch(ordersActions.incrementPageOrders());
      dispatch(ordersActions.setMoreOrders(data));
    } catch ({ response }) {
      if (typeof response === 'undefined') {
        dispatch(
          ordersActions.setErrorOrders({
            status: '0',
            text: null,
          }),
        );
      } else {
        dispatch(
          ordersActions.setErrorOrders({
            status: response.status,
            text: response.data && response.data.detail ? response.data.detail : response.statusText,
          }),
        );
      }
    } finally {
      dispatch(ordersActions.toggleMoreOrders(false));
    }
  };

const exportedObject = {
  getOrders,
  refreshOrders,
  getMoreOrders,
};

export default exportedObject;
