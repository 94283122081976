const TOGGLE_PENDING_VCATEGORY = 'vcategory/TOGGLE_PENDING_VCATEGORY';
const TOGGLE_REFRESHING_VCATEGORY = 'vcategory/TOGGLE_REFRESHING_VCATEGORY';
const SET_VCATEGORY = 'vcategory/SET_VCATEGORY';
const SET_ERROR_VCATEGORY = 'vcategory/SET_ERROR_VCATEGORY';

const exportedObject = {
  TOGGLE_PENDING_VCATEGORY,
  TOGGLE_REFRESHING_VCATEGORY,
  SET_VCATEGORY,
  SET_ERROR_VCATEGORY,
};

export default exportedObject;
