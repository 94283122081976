import { initializeApp } from 'firebase/app';
import { getToken } from 'firebase/messaging';
import { getMessaging } from 'firebase/messaging/sw';

const firebaseConfig = {
  apiKey: 'AIzaSyAgEv7t1kH533NAd1X1MJ-Vw0ipTMU3YRU',
  authDomain: 'your-hotel-app-4a11f.firebaseapp.com',
  projectId: 'your-hotel-app-4a11f',
  storageBucket: 'your-hotel-app-4a11f.appspot.com',
  messagingSenderId: '361289592541',
  appId: '1:361289592541:web:4433350962c1a9e426f534',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'BFbW1MVIz3GaNsIL1fhcj6PgnL376HY1jDsYS0bZ2884o2HBvis8_bShmWU8Dkw31CaupNl9VWEl5ejoNfABeW8',
    });
    if (currentToken) {
      setTokenFound(currentToken);
    } else {
      setTokenFound(false);
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};
