import types from './types';

const togglePendingVcategory = (payload, meta) => ({
  type: types.TOGGLE_PENDING_VCATEGORY,
  payload,
  meta,
});

const toggleRefreshingVcategory = (payload, meta) => ({
  type: types.TOGGLE_REFRESHING_VCATEGORY,
  payload,
  meta,
});

const setVcategory = (payload, meta) => ({
  type: types.SET_VCATEGORY,
  payload,
  meta,
});

const setErrorVcategory = (payload, meta) => ({
  type: types.SET_ERROR_VCATEGORY,
  payload,
  meta,
});

const exportedObject = {
  togglePendingVcategory,
  toggleRefreshingVcategory,
  setVcategory,
  setErrorVcategory,
};

export default exportedObject;
