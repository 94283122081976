const TOGGLE_PENDING_APP_PROPS = 'app/TOGGLE_PENDING_APP_PROPS';
const SET_APP_PROPS = 'app/SET_APP_PROPS';
const SET_ERROR_APP_PROPS = 'app/SET_ERROR_APP_PROPS';

const exportedObject = {
  TOGGLE_PENDING_APP_PROPS,
  SET_APP_PROPS,
  SET_ERROR_APP_PROPS,
};

export default exportedObject;
