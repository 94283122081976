import appActions from './actions';

import appAPI from '../../api/app';

const getAppProps = (project) => async (dispatch) => {
  dispatch(appActions.togglePendingAppProps(true));
  dispatch(
    appActions.setErrorAppProps({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await appAPI.getAppProps(project);
    const projectProps = data.find((itm) => itm.project === project) || {};

    dispatch(appActions.setAppProps(projectProps));

    return data;
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        appActions.setErrorAppProps({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        appActions.setErrorAppProps({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }

    return response;
  } finally {
    dispatch(appActions.togglePendingAppProps(false));
  }
};

const exportedObject = {
  getAppProps,
};

export default exportedObject;
