// ** Lodash
import omit from 'lodash/omit';

import menuActions from './actions';

import menuAPI from '../../api/menu';

const getService = (id) => async (dispatch) => {
  dispatch(menuActions.togglePendingService(true));
  dispatch(
    menuActions.setErrorService({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await menuAPI.getService({
      id: id,
    });
    dispatch(menuActions.setService(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        menuActions.setErrorService({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        menuActions.setErrorService({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(menuActions.togglePendingService(false));
  }
};

const refreshService = (id) => async (dispatch) => {
  dispatch(menuActions.toggleRefreshingService(true));
  dispatch(
    menuActions.setErrorService({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await menuAPI.getService({
      id: id,
    });
    dispatch(menuActions.setService(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        menuActions.setErrorService({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        menuActions.setErrorService({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(menuActions.toggleRefreshingService(false));
  }
};

const getItem = (id) => async (dispatch) => {
  dispatch(menuActions.togglePendingItem(true));
  dispatch(
    menuActions.setErrorItem({
      status: null,
      text: null,
    }),
  );

  try {
    const { data } = await menuAPI.getItem(id);

    dispatch(menuActions.setItem(data));
  } catch ({ response }) {
    if (typeof response === 'undefined') {
      dispatch(
        menuActions.setErrorItem({
          status: '0',
          text: null,
        }),
      );
    } else {
      dispatch(
        menuActions.setErrorItem({
          status: response.status,
          text: response.data && response.data.detail ? response.data.detail : response.statusText,
        }),
      );
    }
  } finally {
    dispatch(menuActions.togglePendingItem(false));
  }
};

const addToCart =
  ({ serviceId, item }) =>
  async (dispatch, getState) => {
    const { menu } = getState();
    const cart = menu.data.cart;

    const cartData = {
      ...cart,
      [serviceId]: {
        service: Number(serviceId),
        form_data: {},
        extra_form_data: {},
        items: [...(cart[serviceId]?.items || []), item],
      },
    };

    dispatch(menuActions.setCartItem(cartData));
  };

const removeFromCart =
  ({ uuid, serviceId }) =>
  async (dispatch, getState) => {
    const { menu } = getState();
    const cart = menu.data.cart;

    const currentCartData = cart[serviceId] || {};
    const currentCartItems = currentCartData.items || [];

    if (currentCartItems.length) {
      const newCartItems = currentCartItems.filter((item) => item.uuid !== uuid);

      const newCartData = {
        ...cart,
        [serviceId]: {
          service: Number(serviceId),
          form_data: {},
          extra_form_data: {},
          items: newCartItems,
        },
      };

      dispatch(menuActions.setCartItem(newCartData));
    }
  };

const editCartItem =
  ({ uuid, serviceId, item }) =>
  async (dispatch, getState) => {
    const { menu } = getState();
    const cart = menu.data.cart;

    const currentCartData = cart[serviceId] || {};
    const currentCartItems = currentCartData.items || [];

    if (currentCartItems.length) {
      const newCartItems = currentCartItems.filter((item) => item.uuid !== uuid);

      const newCartData = {
        ...cart,
        [serviceId]: {
          service: Number(serviceId),
          form_data: {},
          extra_form_data: {},
          items: [...newCartItems, item],
        },
      };

      dispatch(menuActions.setCartItem(newCartData));
    }
  };

const clearCart =
  ({ serviceId }) =>
  async (dispatch, getState) => {
    const { menu } = getState();
    const cart = menu.data.cart;

    const cartData = omit(cart, [serviceId]);

    dispatch(menuActions.setCartItem(cartData));
  };

const submitOrder = (formData) => async () => {
  const response = await menuAPI.submitOrder({
    data: formData,
  });

  return response;
};

const exportedObject = {
  getService,
  refreshService,
  getItem,
  addToCart,
  removeFromCart,
  editCartItem,
  clearCart,
  submitOrder,
};

export default exportedObject;
