import { http2 } from 'src/helpers/http';

export const getAppProps = async () => {
  const response = await http2.get(window.location.origin + '/props.json');

  return response;
};

const exportedObject = {
  getAppProps,
};

export default exportedObject;
