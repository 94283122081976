const TOGGLE_PENDING_STAYS = 'stays/TOGGLE_PENDING_STAYS';
const TOGGLE_REFRESHING_STAYS = 'stays/TOGGLE_REFRESHING_STAYS';
const TOGGLE_MORE_STAYS = 'stays/TOGGLE_MORE_STAYS';
const SET_STAYS = 'stays/SET_STAYS';
const SET_MORE_STAYS = 'stays/SET_MORE_STAYS';
const SET_ERROR_STAYS = 'stays/SET_ERROR_STAYS';
const SET_PAGE_STAYS = 'stays/SET_PAGE_STAYS';
const INCREMENT_PAGE_STAYS = 'stays/INCREMENT_PAGE_STAYS';
const DECREMENT_PAGE_STAYS = 'stays/DECREMENT_PAGE_STAYS';

const TOGGLE_PENDING_HOTEL = 'stays/TOGGLE_PENDING_HOTEL';
const SET_ERROR_HOTEL = 'stays/SET_ERROR_HOTEL';

const exportedObject = {
  TOGGLE_PENDING_STAYS,
  TOGGLE_REFRESHING_STAYS,
  TOGGLE_MORE_STAYS,
  SET_STAYS,
  SET_MORE_STAYS,
  SET_ERROR_STAYS,
  SET_PAGE_STAYS,
  INCREMENT_PAGE_STAYS,
  DECREMENT_PAGE_STAYS,
  TOGGLE_PENDING_HOTEL,
  SET_ERROR_HOTEL,
};

export default exportedObject;
