import { combineReducers } from 'redux';

import types from './types';

const defaultDataState = {
  list: [],
  groups: [],
};

const data = (state = defaultDataState, { type, payload }) => {
  switch (type) {
    case types.SET_NOTIFICATIONS:
      return {
        ...state,
        list: payload.list,
      };
    case types.SET_NOTIFICATIONS_GROUPS:
      return {
        ...state,
        groups: payload,
      };
    case types.CLEAR_NOTIFICATIONS:
      return defaultDataState;
    default:
      return state;
  }
};

const defaultCountState = {};

const count = (state = defaultCountState, { type, payload }) => {
  switch (type) {
    case types.SET_COUNT_NOTIFICATIONS:
      return {
        ...state,
        ...payload,
      };
    case types.CLEAR_NOTIFICATIONS:
      return defaultCountState;
    default:
      return state;
  }
};

const defaultProgressState = {
  pendingNotificationsGroups: false,
};

const progress = (state = defaultProgressState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_PENDING_NOTIFICATIONS_GROUPS:
      return {
        ...state,
        pendingNotificationsGroups: payload,
      };
    default:
      return state;
  }
};

const defaultErrorState = {
  errorMessage: {
    status: null,
    text: null,
  },
  errorMessageNotificationsGroups: {
    status: null,
    text: null,
  },
};

const error = (state = defaultErrorState, { type, payload }) => {
  switch (type) {
    case types.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: payload,
      };
    case types.SET_ERROR_NOTIFICATIONS_GROUPS:
      return {
        ...state,
        errorMessageNotificationsGroups: payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  data,
  count,
  progress,
  error,
});
